import React, { useEffect } from 'react';

const Page = ({ children, title }) => {
    useEffect(() => {
        document.title = title || "Geoffrey Long";
    }, [title]);

    return children;
};

export default Page;
