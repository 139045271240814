import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Maybe could script read from the file itself then strip out the sensitive info and populate?
const HardwareCode = () => (
    <SyntaxHighlighter language="arduino" style={ dark }>
        { `// MPU6050 code based off of https://github.com/ElectronicCats/mpu6050/blob/master/examples/MPU6050_DMP6/MPU6050_DMP6.ino

#include "I2Cdev.h"
#include "MPU6050_6Axis_MotionApps20.h"
#include "Wire.h"
#include "TinyGPS++.h"
#include "SoftwareSerial.h"
#include "LiquidCrystal.h"

#define DEBUG false

// ================================================================
// ===                     Pinouts                              ===
// ================================================================

#define LCD_RS    12
#define LCD_E     10
#define LCD_D4    8
#define LCD_D5    7
#define LCD_D6    6
#define LCD_D7    5

#define INTERRUPT 2
// MPU_SDA => A4
// MPU_SCL => A5

// These might be flipped on my chip
#define GPS_RX    3
#define GPS_TX    4

// ================================================================
// ===                     LCD variables                        ===
// ================================================================

LiquidCrystal lcd(LCD_RS, LCD_E, LCD_D4, LCD_D5, LCD_D6, LCD_D7);

// ================================================================
// ===                     GPS variables                        ===
// ================================================================

SoftwareSerial gpsSerial(GPS_TX, GPS_RX); // My pins may be reversed on the board
TinyGPSPlus gps;

static const uint32_t GPSBaud = 9600;
uint32_t prevGPSTime;
static const int speedQueueSize = 10;
int speedQueuePointer = 0;
double speeds[speedQueueSize];
double smoothedSpeed = 0.0;
bool hasGPSData = false;

// ================================================================
// ===                     MPU variables                        ===
// ================================================================

MPU6050 mpu;

// MPU control/status vars
bool dmpReady = false;  // set true if DMP init was successful
uint8_t mpuIntStatus;   // holds actual interrupt status byte from MPU
uint8_t devStatus;      // return status after each device operation (0 = success, !0 = error)
uint16_t packetSize;    // expected DMP packet size (default is 42 bytes)
uint16_t fifoCount;     // count of all bytes currently in FIFO
uint8_t fifoBuffer[64]; // FIFO storage buffer

// orientation/motion vars
Quaternion q;           // [w, x, y, z]         quaternion container
VectorFloat gravity;    // [x, y, z]            gravity vector
float ypr[3];           // [yaw, pitch, roll]   yaw/pitch/roll container and gravity vector
float pitch = 0.0;
float roll = 0.0;

// MPU6050 interrupt detection routine
volatile bool mpuInterrupt = false;     // indicates whether MPU interrupt pin has gone high
void dmpDataReady() {
    mpuInterrupt = true;
}


// ================================================================
// ===                      INITIAL SETUP                       ===
// ================================================================

void setup() {
  Wire.begin();
  Wire.setClock(400000); // 400kHz I2C clock

  // Initialize serial communication
  if (DEBUG) {
    Serial.begin(115200);
    while (!Serial); // wait for serial
  }

  // Set up LCD
  lcd.begin(16, 2);
  lcd.print("Initializing...");

  // Set up MPU
  setupMPU();

  // Set up GPS
  gpsSerial.begin(GPSBaud);

  // Clear LCD
  lcd.setCursor(0,0);
  lcd.clear();
}

//******** SET UP MPU6050 ********//
void setupMPU() {
  mpu.initialize();
  pinMode(INTERRUPT, INPUT);
  if (DEBUG) Serial.println(mpu.testConnection() ? "MPU6050 connection successful" : "MPU6050 connection failed");
  devStatus = mpu.dmpInitialize();

  // supply your own gyro offsets here, scaled for min sensitivity
  mpu.setXGyroOffset(220);
  mpu.setYGyroOffset(76);
  mpu.setZGyroOffset(-85);
  mpu.setZAccelOffset(1788); // 1688 factory default for my test chip

  // make sure it worked (returns 0 if so)
  if (devStatus == 0) {
      // Calibration Time: generate offsets and calibrate our MPU6050
      mpu.CalibrateAccel(6);
      mpu.CalibrateGyro(6);

      // Set sampling rate to 50Hz
      mpu.setRate(18);

      // Turn on the DMP
      mpu.setDMPEnabled(true);
      attachInterrupt(digitalPinToInterrupt(INTERRUPT), dmpDataReady, RISING);
      mpuIntStatus = mpu.getIntStatus();
      dmpReady = true;

      // Get packet size for later computation
      packetSize = mpu.dmpGetFIFOPacketSize();
  } else if (DEBUG) {
      Serial.print("DMP Initialization failed with code: ");
      Serial.println(devStatus);
  }
}


// ================================================================
// ===                    MAIN PROGRAM LOOP                     ===
// ================================================================

void loop() {
  updateMPUData();
  updateSpeedData();
  writeToLCD();
}

// Read MPU data and update the roll value
void updateMPUData() {
  if (dmpReady) {
    mpuIntStatus = mpu.getIntStatus();
    fifoCount = mpu.getFIFOCount();

    if (fifoCount >= packetSize) {
      // Reset MPU fifo if overflow, else read the data
      if ((mpuIntStatus & (0x01 << MPU6050_INTERRUPT_FIFO_OFLOW_BIT)) || fifoCount >= 1024) {
        mpu.resetFIFO();
      } else if (mpuIntStatus & (0x01 << MPU6050_INTERRUPT_DMP_INT_BIT)) {
        mpu.getFIFOBytes(fifoBuffer, packetSize);

        // Get yaw pitch and roll using the MPU DMP
        mpu.dmpGetQuaternion(&q, fifoBuffer);
        mpu.dmpGetGravity(&gravity, &q);
        mpu.dmpGetYawPitchRoll(ypr, &q, &gravity);

        // Calculate roll, we don't care about pitch for now
        roll = abs(ypr[2] * 180/M_PI);

        // Always reset FIFO
        mpu.resetFIFO();
      }
    }
  }
}

// Read the GPS and update the smoothedSpeed value
void updateSpeedData() {
  // Read available gpsSerial buffer
  while (gpsSerial.available() > 0){
    gps.encode(gpsSerial.read());

    // Only update the speed if we have a new GPS value
    if (gps.location.isUpdated() && gps.time.value() != prevGPSTime){
      double curSpeed = abs(gps.speed.mph());
      prevGPSTime = gps.time.value();

      smoothedSpeed = getSmoothedSpeed(curSpeed);

      // Indicate that we now have GPS connection
      hasGPSData = true;
    }
  }
}

// Use a rolling queue to get an average of the last read speeds for display
double getSmoothedSpeed(double curSpeed) {
  // Add the speed to the queue and increment the queue counter
  speeds[speedQueuePointer] = curSpeed;
  speedQueuePointer = (speedQueuePointer + 1) % speedQueueSize;

  double smoothedSpeedSum = 0.0;
  for (int i = 0; i < speedQueueSize; i++) {
    smoothedSpeedSum += speeds[i];
  }

  return smoothedSpeedSum / speedQueueSize;
}

// Print out the speed and roll on the LCD
void writeToLCD() {
  // Print speed line
  lcd.setCursor(0,0);
  lcd.print("Speed(mph): ");
  if (hasGPSData) {
    char speedString[4];
    dtostrf(smoothedSpeed, 4, 1, speedString);
    lcd.print(speedString);
  } else {
    lcd.print("  --");
  }

  // Print roll line
  char rollString[4];
  dtostrf(roll, 4, 1, rollString);
  lcd.setCursor(0,1);
  lcd.print("Angle(deg): ");
  lcd.print(rollString);

  if (DEBUG) {
    Serial.println(smoothedSpeed);
    Serial.println(roll);
  }
}` }
    </SyntaxHighlighter>
);

export default HardwareCode;
