import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { colors } from '../Theme';

const useStyles = createUseStyles(theme => ({
    active: {
        fontWeight: 'bold',
        color: theme.colors.specialCase.gold
    }
}));

const RadioButtonBar = ({ onChange, options, value }) => (
    <ButtonGroup aria-label="text primary button group" color="primary" variant="text">
        { options.map(option => (
            <Button
                disabled={ option.disabled }
                key={ `radio-button-bar-option-${ option.value }` }
                onClick={ () => onChange(option.value) }
                sx={{
                    fontWeight: value === option.value ? 900 : 400,
                    color: value === option.value && colors.gold
                }}
            >
                { option.label }
            </Button>
        )) }
    </ButtonGroup>
);

RadioButtonBar.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.string
};

export default RadioButtonBar;
