import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import AppConstants from '../../AppConstants';
import ProjectCard from './ProjectCard';
import Pages, { pageKeys } from '../../Pages';
import Strings from '../../translations/strings/en';

const useStyles = createUseStyles(theme => ({
    splashContainer: {
        position: 'relative',
        width: '100%',
        background: theme.colors.background,
        minHeight: `calc(100% - ${ AppConstants.headerHeight })`,
        display: 'flex',
        flexDirection: 'column'
    },
    bannerContainer: {
        textAlign: 'center',
        height: '75vh',
        backgroundImage: 'url(/img/space_background_3_slimmer.png)',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
            height: '60vh'
        }
    },
    titleOverlayContainer: {
        paddingTop: '20vh',
        color: theme.colors.lightestText
    },
    primaryTitle: {
        fontWeight: theme.typography.bold,
        fontSize: theme.sizes.xxxxl,
        display: 'inline-block',
        padding: '0.5rem 1rem',
        margin: '0 1rem',
        borderTop: `3px solid ${ theme.colors.lightestText }`,
        borderBottom: `3px solid ${ theme.colors.lightestText }`
    },
    secondaryTitle: {
        fontSize: theme.sizes.xxl,
        marginTop: '2rem'
    },
    projectContainer: {
        width: '100%'
    },
    titleContainer: {
        marginTop: '0.75rem',
        width: '100%',
        textAlign: 'center'
    },
    title: {
        display: 'inline-block',
        color: theme.colors.delta,
        padding: '0.25rem 1rem',
        fontSize: theme.sizes.xxxl,
        textTransform: 'uppercase',
        borderBottom: `3px solid ${ theme.colors.delta }`,
        borderTop: `3px solid ${ theme.colors.delta }`
    },
    projects: {
        flex: 1,
        padding: '1.25rem 1rem 1rem',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    }
}));

const Splash = () => {
    const styles = useStyles(useTheme());

    return (
        <div className={ styles.splashContainer }>
            <div className={ styles.bannerContainer }>
                <div className={ styles.titleOverlayContainer }>
                    <div className={ styles.primaryTitle }>
                        { Strings.splash.title }
                    </div>
                    <div className={ styles.secondaryTitle }>
                        { Strings.splash.subtitle }
                    </div>
                </div>
            </div>
            <div className={ styles.projectContainer }>
                <div className={ styles.titleContainer }>
                    <h2 className={ styles.title }>{ Strings.shared.projects }</h2>
                </div>
                <div className={ styles.projects }>
                    <ProjectCard
                        image="/img/water_temp_sensor_outdoors.jpg"
                        imageTitle={ Strings.lakeSensorV1.title }
                        link={ Pages[pageKeys.lakeTemps]?.route }
                        subTitle={ Strings.lakeSensorV1.subtitle }
                        title={ Strings.lakeSensorV1.title }
                    />
                    <ProjectCard
                        image="/img/sailboat_sensor_outdoors.jpg"
                        imageTitle={ Strings.sailboatSensor.title }
                        link={ Pages[pageKeys.sailboatSensor]?.route }
                        subTitle={ Strings.sailboatSensor.subtitle }
                        title={ Strings.sailboatSensor.title }
                    />
                    <ProjectCard
                        image="/img/beehive_monitor_full_cropped_edited.png"
                        imageTitle={ Strings.beehiveSensor.title }
                        link={ Pages[pageKeys.beehiveMonitor]?.route }
                        subTitle={ Strings.beehiveSensor.subtitle }
                        title={ Strings.beehiveSensor.title }
                    />
                </div>
            </div>
        </div>
    );
};

export default Splash;
