import React from 'react';
import PropTypes from 'prop-types';

import { Card as MuiCard } from '@mui/material';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
    card: {
        flex: 1,
        margin: '0.5rem',
        padding: '1rem',
        minWidth: '20rem'
    },
    cardHeader: {
        display: 'flex',
        paddingBottom: '0.5rem',
        marginBottom: '0.5rem',
        borderBottom: `1px solid ${ theme.colors.headerShadow }`,
        alignItems: 'baseline',
        justifyContent: 'space-between'
    },
    title: {
        fontWeight: 'bold',
        fontSize: theme.sizes.lg
    }
}));

const Card = ({ children, title, titleRight }) => {
    const styles = useStyles(useTheme());

    return (
        <MuiCard classes={ { root: styles.card } }>
            <div className={ styles.cardHeader }>
                <span className={ styles.title }>{ title }</span>
                <div>{ titleRight }</div>
            </div>
            { children }
        </MuiCard>
    );
};

Card.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    titleRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default Card;
