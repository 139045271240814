import moment from 'moment';
import AppConstants from '../AppConstants';
import { API, graphqlOperation } from 'aws-amplify';

const getQueryVariables = runVersion => ({
    sort: { field: 'createdAt', direction: 'desc' },
    limit: AppConstants.graphqlLimit,
    filter: { runVersion: { eq: runVersion } }
});

// TODO fix interval
// NOTE max expected based on an expected interval of 15 minutes
// If we would like to alter this we can either
// (a) calculate these values based on an added SensorInfo field "pollingInterval"
// (b) simply loop over the data twice, once to filter out the temps outside the interval, the second to cut the data size
//      This would remove the need for maxExpected, and would be simpler overall. With useMemo also, we don't have to be overly concerned about the efficiency here.
export const intervalFilters = {
    [AppConstants.graphIntervals.TODAY]: {
        getCreatedAt: latestTime => latestTime.startOf('day').toISOString(),
        maxExpected: 96
    },
    [AppConstants.graphIntervals.THREE_DAYS]: {
        getCreatedAt: latestTime => latestTime.subtract(3, 'days').toISOString(),
        maxExpected: 288,
        intervalMS: 259200000
    },
    [AppConstants.graphIntervals.ONE_WEEK]: {
        getCreatedAt: latestTime => latestTime.subtract(1, 'weeks').toISOString(),
        maxExpected: 672,
        intervalMS: 604800000
    },
    [AppConstants.graphIntervals.ONE_MONTH]: {
        getCreatedAt: latestTime => latestTime.subtract(1, 'months').toISOString(),
        maxExpected: 2976,
        intervalMS: 2678400000
    },
    [AppConstants.graphIntervals.THREE_MONTHS]: {
        getCreatedAt: latestTime => latestTime.subtract(3, 'months').toISOString(),
        maxExpected: 8928,
        intervalMS: 7952400000
    }
};

const getSortedData = async (apiOperation, apiDataKey, runVersion, nextToken) => {
    const search = await API.graphql(graphqlOperation(
        apiOperation,
        { nextToken, ...getQueryVariables(runVersion) }
    ));

    const data = search?.data?.[apiDataKey]?.items || [];

    // Only fetch the next if we have more data to fetch, else will get an empty response
    return {
        data,
        nextToken: data.length >= AppConstants.graphqlLimit && search?.data?.[apiDataKey]?.nextToken
    };
};

// Need to wrap the async inside instead of on the effect function itself
export const getData = async (apiOperation, apiDataKey, runVersion) => {
    let { data, nextToken } = await getSortedData(apiOperation, apiDataKey, runVersion);
    let mergedData = data || [];

    // Iterate while we have nextTokens to get all the data
    while (nextToken) {
        ({ data, nextToken } = await getSortedData(apiOperation, apiDataKey, runVersion, nextToken));
        mergedData = mergedData.concat(data);
    }

    return mergedData;
};

// TODO fix useMemo. useMemo does not cache all, only the latest call
// Filter values to only have a renderable number of data points from within the interval
export const dataMemo = (data, interval) => {
    // Get the latest data point to calculate the interval from
    const intervalEndTimestamp = data[0]?.createdAt;
    const intervalEnd = intervalEndTimestamp ? moment(intervalEndTimestamp) : moment();
    const intervalBegin = intervalFilters[interval].getCreatedAt(intervalEnd);

    // Figure out the "skip factor" so we never render over 400 data points
    const moduloValue = Math.ceil(Math.min(data.length, intervalFilters[interval].maxExpected) / 400);

    // Filter so we only have values from within the interval with the desired skip factor
    // Note: modulo === 0 should return the latest data point
    return data.filter((temp, idx) => temp.createdAt >= intervalBegin && idx % moduloValue === 0);
};
