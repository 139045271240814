import Pages, { pageKeys } from '../../Pages';

export const LeftNavigationItems = [
    pageKeys.lakeTemps,
    pageKeys.sailboatSensor,
    pageKeys.beehiveMonitor
];

export const TopNavigationItemsComputer = [
    pageKeys.splash,
    pageKeys.projects
];

export const TopNavigationItemsMobile = [
    pageKeys.splash,
    pageKeys.lakeTemps,
    pageKeys.sailboatSensor,
    pageKeys.beehiveMonitor
];

const getUrlMatchLength = (path, pageKey) => path.match(Pages[pageKey]?.route)?.length;
export const getNavItemFromUrl = (navItems, path) => (
    // If any pageKey route matches the current path, return that tab
    // Else return the tab with the longest path match
    navItems.reduce((tab, curPageKey) => {
        if (Pages[tab]?.route === path) {
            return tab;
        } else if (Pages[curPageKey]?.route === path) {
            return curPageKey;
        } else {
            return getUrlMatchLength(path, tab) > getUrlMatchLength(path, curPageKey) ? tab : curPageKey;
        }
    })
);
