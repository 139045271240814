import React from 'react';
import Learnings from '../../components/Learnings';

const learnings = [
    {
        title: 'Unable to connect to 5 GHz network from ESP8266',
        icon: '',
        symptoms: 'The ESP8266 wifi connection loop would just spin, never successfully connecting to the network.',
        reason: 'ESP8266 does not support 5 GHz bandwidth.',
        solution: 'I had to switch to a 2.4 GHz network.',

        description: 'This occurred because I was attempting to access a 5g network when the ESP8266 does not support that bandwidth.'
    },
    {
        title: 'Unable to connect to AWS Amplify server through ESP8266',
        icon: '',
        symptoms: 'My http post was throwing errors (could have been 401\'s).',
        reason: 'The server was behind https, which requires a certificate fingerprint to access.',
        solution: 'I had to set a fingerprint in the ESP8266 wifi client connection code. In order to get this fingerprint, I had to visit the api url in chrome and copy the sha-1 from the certificate details (behind the lock in the url bar).',

        description: 'My server was actually behind https. To solve this one, I had to go to the server https address directly, click on the lock in chrome, view the certificate, then copy the sha-1. In my sensor code, I updated to use WiFiClientSecure (in the default http client) and I used setFingerprint on that WiFiClientSecure variable. Then I was able to connect by beginning my https connection with that wifi client variable.'
    },
    {
        title: 'Unable to implement a simple sort query for a graphql model',
        icon: '',
        symptoms: 'In order to implement sorting, most tutorials recommend using the @key directive. Unfortunately, adding @key["createdAt"] would still require an id to be passed to the sort, which rendered the sort unusable.',
        reason: 'GraphQL requires two keys to be passed. The first is the "hash" key for a strict equality check, the second is the sort key.',
        solution: 'Added a dataType field so the key array was ["dataType", "createdAt"]. Then I was able to search, but I had to add a dataType string to all my mutations and my sort queries, which was tedious. I eventually just switched to @searchable, which provided a wider range of filtering options out of the box without any hacks.',

        description: 'I had several issues with graphQL throughout my project. One of these was with sorting my database records. When I initially tried with the key directive, I tried doing [createdAt], not knowing that the id field would be automatically added. I eventually got it to work with [schemaType, createdAt], but this was clumsy as I had to add "lakeTemp" as the schemaType when both setting and searching. I eventually moved to @searchable partially because of this, though there is a chance that it would have been possible if I removed the id field and had the single key (did not test).'
    },
    {
        title: 'Could not update graphQL records (pt 1)',
        icon: '',
        symptoms: 'The mutations required an id field for updates, and adding a key of ["runVerion"] didn\'t work.',
        reason: 'Once again, graphQL is weird with single keys, but the main reason was that I had an "id" field in the schema.',
        solution: 'Once I removed the id field from the schema, I was able to update using just the runVersion.', // TODO check if this would fix the sorting issues above

        description: 'I had a few issues with updating database entries. I eventually realized that I needed a key, but when I added [runVersion] as the key I still needed to enter an id to update it. That\'s when I discovered that the id would be automatically added for everything key related when it was in the schema. I removed that and I could udpate with just the runVersion. I never tried to see if sorting would work without the id field, but that could be the case.'
    },
    {
        title: 'Could not update graphQL records (pt 2)',
        icon: '',
        symptoms: 'Errors saying "Conflict resolver rejects mutation" when attempting to update records.',
        reason: 'Amplify conflict resolution was on.',
        solution: 'This one was particularly frustrating because I was unable to remove conflict resolution by simply running "amplify update api" on the command line. I actually had to edit the "transform.conf.json" files and delete the conflictResolution keys. Another option would have been to work with conflict resolution (fetch, mutate, then push), but this would have been an unnecessary overhead since the sensor is the only entity mutating the db.',

        description: 'After getting my update functionality working through the AWS Amplify web client, I tried to run it through my code. I kept getting errors saying "Conflict resolver rejects mutation". Unfortunately, there wasn\'t much information on conflict resolution errors on the internet. I tried adding an auth directive, but that didn\'t work. I tried removing _version, but got errors of "Unsupported element \'$[_version]\'". I tried adding in a version, but got errors saying that I couldn\'t update that field. Perhaps it would have worked to pull the data, update, then push, but I didn\'t need that complexity if it was just my sensor connecting and updating. I resolved to remove the resolver. I tried removing it using "amplify api update" and selecting "No" for setting up conflict resolution, but this didn\'t actually turn it off. I actually had to go into "transform.conf.json" and delete the whole conflict resolution section. It is super tedious that I couldn\'t just do so through the command line.'
    },
    {
        title: 'DS18B20 sensor not reading temperature data',
        icon: '',
        symptoms: 'The pin connected to the DS18B20 sensor was reading -196 degrees consistently.',
        reason: 'I had my board loose on the headers (which were connected to the breadboard), so the connection was not good enough for the 1-wire protocol.',
        solution: 'I had to solder the headers onto the board.',

        description: 'When I first attempted to connect to the DS18B20, I would only get back readings of 196 degrees. I tried everything: switching pins, switching sensors, reversing the wires, checking the voltage and amperage of the circuit, changing libraries, and even editing the sensor library module. Eventually, I discovered that the headers actually had to be soldered on the pins! I had connected the headers to a breadboard and just had the board inserted to these headers. The voltage carried, but apparently this connection was not good enough for the Dallas 1-wire protocol.'
    },
    {
        title: 'Could not load code after implementing deep sleep',
        icon: '',
        symptoms: 'The arduino IDE would spin on "connecting" to the board.',
        reason: 'It was something to do with the RST=>GPIO16 connection. It does manage to upload code with this wire connected some of the time, but not every time.',
        solution: 'I have to remove the RST=>GPIO16 connection when loading code.',

        description: 'I had an issue connecting to my board after implementing my deep sleep functionality. It would just spin on "connecting". I quickly found that removing the RST=>GPIO16 connection would allow the code to load.'
    },
    {
        title: 'Had difficulties adding auth to sensor code',
        icon: '',
        symptoms: 'The default for authentication, api key, was not granular enough. All the created keys would have the same permissions, meaning that I could not have a different, secret key that would allow only the sensor to mutate database records. The other authentication methods required extra calls to AWS that would drain my sensor battery.',
        reason: 'AWS authentication is built for more robust authentication than I required.',
        solution: 'I managed to add "API secret" logic using custom graphQL resolvers and an x-api-secret header in the sensor mutation requests. In the amplify code, I copied all the *.req.vtl mutations (create, update, and delete) from build/resolvers into new resolver files I created with the same names. To the newly created create and update resolvers, I added #if( $context.request.headers["x-api-secret"] != "MY_API_SECRET" ) $util.error("Not authorized") #end. And at the top of the deletes, I simply added $util.error("Not allowed"), which is a quick and dirty way of preventing anything from deleting records. The result is that everyone can read, the sensor can create and update, and nobody can delete. On the sensor side, I simply added an "x-api-secret" header to the sensor\'s api requests.'
    }
];
const LakeLearnings = () => (
    <Learnings learnings={ learnings } />
);

export default LakeLearnings;
