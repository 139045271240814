import {Tab, Tabs} from "@mui/material";
import Pages from "../../Pages";
import AppConstants from "../../AppConstants";
import {colors} from "../../Theme";
import React, {useState} from "react";
import { withRouter } from "react-router-dom";
import { getNavItemFromUrl, TopNavigationItemsComputer } from "./Navigation";

const HeaderMenuComputer = ({ history, location }) => {
    const [curTab, setCurTab] = useState(getNavItemFromUrl(TopNavigationItemsComputer, location.pathname));

    return (
        <Tabs
            indicatorColor="primary"
            onChange={ (e, pageKey) => {
                setCurTab(pageKey);
                history.push(Pages[pageKey]?.route);
            } }
            sx={{
                minHeight: AppConstants.headerHeight, // Necessary for whatever reason TODO
                height: AppConstants.headerHeight,
                [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
                    display: 'none'
                }
            }}
            TabIndicatorProps={ { style: { backgroundColor: colors.gold } } }
            textColor="primary"
            value={ curTab }
        >
            { TopNavigationItemsComputer.map(pageKey => (
                <Tab
                    sx={{
                        color: colors.almostWhite,
                        minHeight: AppConstants.headerHeight, // Necessary for whatever reason TODO
                        height: AppConstants.headerHeight,
                        minWidth: '0',
                        fontWeight: 600,
                        '&.Mui-selected': {
                            color: colors.gold
                        }
                    }}
                    key={ `nav-item-${ pageKey }` } label={ Pages[pageKey].title }
                    value={ pageKey }
                />
            )) }
        </Tabs>
    );
};

export default withRouter(HeaderMenuComputer);
