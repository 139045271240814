import React from 'react';

import PageHeader from '../../components/PageHeader.jsx';
import { Route, Switch } from 'react-router-dom';
import Pages, { pageKeys } from '../../Pages.js';
import HouseHumidityData from './HouseHumidityData';
import HouseHumidityAbout from './HouseHumidityAbout';
import HouseHumidityLearnings from './HouseHumidityLearnings';

const houseHumidityTabs = {
    DATA: Pages[pageKeys.houseHumidity]?.route,
    ABOUT: Pages[pageKeys.houseHumidityAbout]?.route,
    LEARNINGS: Pages[pageKeys.houseHumidityLearnings]?.route
};
const HouseHumidity = () => (
    <div>
        <PageHeader
            subtitle="A sensor that monitors humidity and temperatures in our house."
            /*
            tabs={ [{
                label: 'Data',
                value: houseHumidityTabs.DATA
            }, {
                label: 'About',
                value: houseHumidityTabs.ABOUT
            }, {
                label: 'Learnings',
                value: houseHumidityTabs.LEARNINGS
            }] }
            */
            title="House Humidity"
        />
        <Switch>
            <Route path={ houseHumidityTabs.ABOUT }>
                <HouseHumidityAbout />
            </Route>
            <Route path={ houseHumidityTabs.LEARNINGS }>
                <HouseHumidityLearnings />
            </Route>
            <Route path={ houseHumidityTabs.DATA }>
                <HouseHumidityData />
            </Route>
        </Switch>
    </div>
);

export default HouseHumidity;
