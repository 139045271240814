import React from 'react';

const HouseHumidityAbout = () => {

    return (
        <div>About</div>
    );
};

export default HouseHumidityAbout;
