import React from 'react';
import PropTypes from 'prop-types';

const unitS = 1000;
const unitM = 60*unitS;
const unitH = 60*unitM;
const unitD = 24*unitH;

const units = [{
    label: 'days',
    divisor: unitD,
    min: 2
}, {
    label: 'hours',
    divisor: unitH,
    min: 3
}, {
    label: 'minutes',
    divisor: unitM,
    min: 3
}, {
    label: 'seconds',
    divisor: unitS,
    min: 10
}, {
    label: 'ms',
    divisor: 1,
    min: 0
}];
const DurationDisplay = ({ durationMs }) => {
    if (!durationMs) {
        return '--';
    }

    // A count of 0 indicates that we have no values set, so we should set one if it is above the minimum
    // A count of 1 indicates that we have a value set, so we should set the second unit as the remainder always
    let count = 0;
    return (
        <span>
            { units.map((unit, idx) => {
                let durationString = '';

                if (count === 0) {
                    const duration = Math.floor(durationMs / unit.divisor);

                    if (duration > unit.min) {
                        durationString = unit.label === 'seconds' ? duration.toFixed(1) : duration;
                    }
                } else if (count === 1 && unit.label !== 'ms') { // Don't want to run this for ms if we don't have to
                    const remainderDuration = durationMs % units[idx-1]?.divisor;

                    durationString = (remainderDuration / unit.divisor).toFixed(1);
                }

                if (durationString) {
                    count = count + 1;
                    return `${ durationString } ${ unit.label } `;
                }

                return '';
            }) }
        </span>
    );
};

DurationDisplay.propTypes = {
    durationMs: PropTypes.number
};

export default DurationDisplay;
