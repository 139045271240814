import React, { useEffect, useState } from 'react';
import { BarChart, Timeline, AccountTree, BubbleChart } from "@mui/icons-material";
import SvgIcon from '@mui/material/SvgIcon';
import { keyframes } from '@emotion/react';
import Theme from '../Theme';

const icons = [
    BarChart, Timeline, AccountTree, BubbleChart
];
const wave = keyframes`
  0%,100% { opacity: 0 }
  70% { opacity: 0.9 }
`;
const GraphLoadingIcons = () => {
    const [curIconIndex, setCurIconIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => setCurIconIndex(idx => (idx + 1) % icons.length), 2700);
        return () => clearTimeout(timer);
    }, []);

    return (
        <SvgIcon
            component={ icons[curIconIndex] }
            sx={ {
                opacity: 0,
                fontSize: Theme.sizes.xxxxl,
                color: Theme.colors.mediumDarkText,
                animation: `${wave} 2.5s ease-in`
            } }
        />
    );
};

export default GraphLoadingIcons;
