import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import InfoContainer from './InfoContainer';
import InfoSection from './InfoSection';

// TODO add some formatting to these so they look prettier, especially the auth one
const useStyles = createUseStyles(theme => ({
    list: {
        padding: '1rem',
        listStyle: 'none',
        '& li': {
            marginBottom: '2rem'
        },
        '& h3:first-of-type': {
            marginTop: 0
        }
    },
    dataList: {
        columnGap: '0.5rem',
        rowGap: '0.5rem',
        display: 'grid',
        gridTemplateColumns: '3.5rem auto',
        '& dt': {
            color: theme.colors.mediumDarkText
        }
    }
}));

// NOTE not using description currently
const Learnings = ({ learnings }) => {
    const styles = useStyles(useTheme());

    return (
        <InfoContainer>
            <InfoSection>
                <ul className={ styles.list }>
                    { learnings.map((learning, idx) => (
                        <li key={ `learning-${ idx }` }>
                            <h3>{ learning.title }</h3>
                            <dl className={ styles.dataList }>
                                <dt>Symptoms</dt>
                                <dd>{ learning.symptoms }</dd>
                                <dt>Reason</dt>
                                <dd>{ learning.reason }</dd>
                                <dt>Solution</dt>
                                <dd>{ learning.solution }</dd>
                            </dl>
                        </li>
                    )) }
                </ul>
            </InfoSection>
        </InfoContainer>
    );
};

Learnings.propTypes = {
    learnings: PropTypes.array
};

export default Learnings;
