import { Menu as MenuIcon} from "@mui/icons-material";
import AppConstants from "../../AppConstants";
import {ListItemIcon, Menu, MenuItem} from "@mui/material";
import Pages from "../../Pages";
import React, {Fragment, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import { Link, withRouter } from 'react-router-dom';
import { getNavItemFromUrl, TopNavigationItemsMobile} from "./Navigation";

const useStyles = createUseStyles(theme => ({
    isActive: {
        color: theme.colors.beta,
        fontWeight: theme.typography.semiBold
    }
}));
const HeaderMenuMobile = ({ history, location }) => {
    const styles = useStyles(useTheme());
    const [curNavItem, setCurNavItem] = useState(getNavItemFromUrl(TopNavigationItemsMobile, location.pathname));
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => setAnchorEl(null);

    return (
        <Fragment>
            <MenuIcon
                fontSize="large"
                onClick={ e => {
                    setCurNavItem(getNavItemFromUrl(TopNavigationItemsMobile, location.pathname));
                    setAnchorEl(e.currentTarget);
                } }
                sx={{
                    verticalAlign: 'middle',
                    [`@media(min-width: ${ AppConstants.mobileWidth })`]: {
                        display: 'none'
                    }
                }}
            />
            <Menu
                anchorEl={ anchorEl }
                anchorOrigin={ {
                    vertical: 'bottom',
                    horizontal: 'left'
                } }
                onClose={ handleClose }
                open={ Boolean(anchorEl) }
                transformOrigin={ {
                    vertical: 'top',
                    horizontal: 'center'
                } }
            >
                { TopNavigationItemsMobile.map((navItem) => {
                    const page = Pages[navItem] || {};
                    const isActive = navItem === curNavItem;

                    return (
                        <MenuItem
                            disabled={ isActive }
                            key={ `header-nav-item-${ navItem }` }
                            onClick={ () => {
                                handleClose();
                                history.push(Pages[navItem]?.route);
                            } }
                            sx={{
                                '&.Mui-disabled': {
                                    opacity: 1
                                }
                            }}
                        >
                            <ListItemIcon sx={{ opacity: isActive ? 0.38 : 1 }}>
                                { page.icon }
                            </ListItemIcon>
                            <Link className={ isActive ? styles.isActive : undefined } to={ page.route }>{ page.title }</Link>
                        </MenuItem>
                    );
                }) }
            </Menu>
        </Fragment>

    )
}

export default withRouter(HeaderMenuMobile);
