import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment-timezone';

import Card from '../../../components/Card.jsx';
import { createUseStyles, useTheme } from 'react-jss';
import { getSensorInfo } from '../../../graphql/queries';
import { OfflineBoltOutlined, PlusOne, DirectionsRun, Timer } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import DurationDisplay from '../../../components/DurationDisplay';
import AppConstants from '../../../AppConstants';

const useStyles = createUseStyles(theme => ({
    dataTable: {
        margin: '0.75rem',
        '& tr': {
            fontSize: theme.sizes.lg
        }
    },
    label: {
        padding: '1rem',
        color: theme.colors.mediumDarkText
    },
    lightning: {
        color: theme.colors.specialCase.brightYellow,
        display: 'block'
    },
    numReadings: {
        color: theme.colors.specialCase.darkMatterDarkest
    },
    activeRunTime: {
        color: theme.colors.specialCase.green
    },
    totalRunTime: {
        color: theme.colors.specialCase.darkMatterDarkest
    }
}));
const useClasses = makeStyles({
    icon: {
        display: 'block'
    }
});
// mA of battery divided by totalCycleTime for run one
// Will want to average for subsequent runs to get a better estimate
const estimatedMADrawPerMs = 2000 / 12617212;
const SensorInfo = () => {
    const styles = useStyles(useTheme());
    const classes = useClasses();
    const [curData, setCurData] = useState({});

    // Fetch data on mount
    useEffect(() => {
        // Fetch the current data
        API.graphql(graphqlOperation(
            getSensorInfo,
            { runVersion: AppConstants.lakeRunVersion }
        )).then((response) => {
            setCurData(response?.data?.getSensorInfo || {});
        });
    }, []);

    const createdAt = curData?.createdAt;
    const curTime = moment().tz('America/New_York');
    const totalRunTime = createdAt ? moment.duration(curTime.diff(createdAt))?.asMilliseconds() : 0;
    const batterymAh = curData?.batterymAh;

    return (
        <Card title="Sensor Info">
            <table className={ styles.dataTable }>
                <tbody>
                    <tr>
                        <td><OfflineBoltOutlined  classes={ { root: classes.icon } } className={ styles.lightning } fontSize="large" /></td>
                        <td className={ styles.label }>Estimated battery remaining</td>
                        <td>
                            { batterymAh && (
                                <span>{ ((batterymAh - curData.totalCycleTime * estimatedMADrawPerMs) / batterymAh * 100)?.toFixed(1) }% of { batterymAh }mAh</span>
                            ) }
                        </td>
                    </tr>
                    <tr>
                        <td><PlusOne classes={ { root: classes.icon } } className={ styles.numReadings } fontSize="large" /></td>
                        <td className={ styles.label }>Number of readings</td>
                        <td>{ curData.counter || '--' }</td>
                    </tr>
                    <tr>
                        <td><DirectionsRun classes={ { root: classes.icon } } className={ styles.activeRunTime } fontSize="large" /></td>
                        <td className={ styles.label }>Active run time</td>
                        <td><DurationDisplay durationMs={ curData.totalCycleTime } /></td>
                    </tr>
                    <tr>
                        <td><Timer classes={ { root: classes.icon } } className={ styles.totalRunTime } fontSize="large" /></td>
                        <td className={ styles.label }>Total run time</td>
                        <td><DurationDisplay durationMs={ totalRunTime } /></td>
                    </tr>
                </tbody>
            </table>
        </Card>
    );
};

export default SensorInfo;
