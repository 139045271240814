// TODO add general flow section (not very clear from the cards)

import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { CheckBox, IndeterminateCheckBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Pages, { pageKeys } from '../../Pages';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import HardwareCode from './HardwareCode';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import AppConstants from '../../AppConstants';
import InfoContainer from '../../components/InfoContainer';
import InfoSection from '../../components/InfoSection';
import InfoExpando from '../../components/InfoExpando';

const useStyles = createUseStyles(theme => ({
    list: {
        paddingLeft: '1rem',
        listStyle: 'none',
        '& li': {
            marginBottom: '0.5rem'
        }
    },
    goal: {
        display: 'flex',
        alignItems: 'center'
    },
    goalIcon: {
        marginRight: '0.5rem'
    },
    successIcon: {
        color: theme.colors.success
    },
    inProgressIcon: {
        color: theme.colors.caution
    },
    description: {
        color: theme.colors.mediumLightText,
        paddingLeft: '2rem',
        fontStyle: 'italic'
    },
    futureDescription: {
        color: theme.colors.mediumLightText,
        fontStyle: 'italic'
    },
    hardwareImgs: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1rem 3rem',
        [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
            margin: '1rem 0'
        }
    },
    img: {
        width: '40%',
        [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
            width: '49%'
        }
    },
    tableContainer: {
        marginLeft: '3rem',
        [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
            marginLeft: 0
        }
    }
}));
const secondaryObjectives = [{
    status: 'DONE',
    goal: 'Give my Dad a gift for Father\'s day 2020',
    description: 'Successfully installed six days before Father\'s day.'
}, {
    status: 'DONE',
    goal: 'Deploy my first hardware project',
    description: 'This is the first (subjectively) useful hardware project I have completed and deployed. I have worked on hardware through hackathons, but those have been abandoned after hackathon close.'
}, {
    status: 'DONE',
    goal: 'Learn new technologies',
    description: 'Learned arduino wifi communications, AWS amplify, graphQL, practical circuitry, soldering, and domain hosting (again).'
}];
const futureObjectives = [{
    goal: 'Improve SensorInfo data handling logic', // TODO actually need to do this one before the next run
    description: 'Currently, the SensorInfo entry for the particular sensor run is created the first time the sensor boots up and is updated on subsequent loops. The logic for determining initial vs subsequent loop is based on the rtc memory. If a marker flag in the rtc memory is set, then we are in a subsequent loop. This works in most cases, however, in the last run, the sensor completely died and the rtc memory was wiped. Somehow, the sensor turned back on a few weeks later and, since there was no rtc memory data, the sensor assumed we were on the initial loop. This overwrote the SensorInfo entry. This logic needs to be refactored so that initial vs subsequent loops is determined based on whether there is an entry for the runVersion in the database.'
}, {
    goal: 'Add toggle to switch between fahrenheit and celsius',
    description: 'The data is currently stored, so this should be relatively easy.'
}, {
    goal: 'Add a "Recent highs and lows" data card',
    description: 'This was postponed because it requires an aggregation (max/min over several ranges) that is not easy with AWS Amplify graphQL.'
}, {
    goal: 'Improve culling logic for large interval graph datapoints',
    description: 'Currently, in order to render graphs over large intervals, I remove every X data point (based on a modulo of numDataPoints/400). For sufficiently large datasets, this could render a low on some days and a high on others. E.g. three months would give one data point roughly every 5 hours.'
}, {
    goal: 'Add estimated battery based on active and total run time',
    description: 'This would be more accurate if I wait until the battery dies, and calculate it from the collected SensorInfo.' // TODO add an (?) when I do explaining the calc
}, {
    goal: 'Remove reliance on OpenWeather API',
    description: 'This would require me to add an extra temperature sensor, a light sensor, and a wind sensor. This is certainly achievable, but I would need some extra cable glands and sensors. I would also need to add a second breadboard for all the connections. These changes, especially the light sensor addition, would greatly improve the accuracy of those fields.'
}, {
    goal: 'Add other metrics to the graph',
    description: 'It would be interesting to see wind and cloud cover / light on the graph. The values may need to be normalized to make them comparable.'
}, {
    goal: 'Offline data storing mode if internet is out',
    description: 'I have not run into this issue... yet.'
}];
const LakeAbout = () => {
    const styles = useStyles(useTheme());

    const renderGoalList = list => (
        <ul className={ styles.list }>
            { list.map((objective, idx) => (
                <li key={ `objective-${ idx }` }>
                    <div className={ styles.goal }>
                        { objective.status === 'DONE'
                            ? <CheckBox className={ [styles.goalIcon, styles.successIcon].join(' ') } />
                            : <IndeterminateCheckBox className={ [styles.goalIcon, styles.inProgressIcon].join(' ') } />
                        }
                        { objective.goal }
                    </div>
                    <div className={ styles.description }>
                        { objective.description }
                    </div>
                </li>
            )) }
        </ul>
    );

    return (
        <InfoContainer>
            <InfoSection>
                <InfoExpando defaultExpanded title="Objectives">
                    <h3>Primary Objective</h3>
                    <p>
                        Create a water temperature sensor in order to discover how the temperature of the lake fluctuates at different depths throughout the summer.
                    </p>
                    <h3>Secondary Objectives</h3>
                    { renderGoalList(secondaryObjectives) }
                </InfoExpando>
            </InfoSection>


            <InfoSection>
                <InfoExpando defaultExpanded title="Hardware">
                    <h3>Hardware schematics</h3>
                    <div className={ styles.hardwareImgs }>
                        <img alt="Lake temp sensor schematic" className={ styles.img } src="/img/esp8266_temp_sensor.png" />
                    </div>

                    <h3>Hardware implementation</h3>
                    <div className={ styles.hardwareImgs }>
                        <img alt="Lake temp sensor circuit" className={ styles.img } src="/img/water_temp_sensor.jpg" />
                        <img alt="Lake temp sensor in use" className={ styles.img } src="/img/water_temp_sensor_outdoors.jpg" />
                    </div>

                    <h3>Hardware components</h3>
                    <p>A list of all the components that were in the final iteration of the sensor</p>
                    <div className={ styles.tableContainer }>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Component</TableCell>
                                    <TableCell>Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Flanged Weatherproof Enclosure with PG-7 Cable Glands</TableCell>
                                    <TableCell>$9.95</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Adafruit Feather HUZZAH with ESP8266 - Loose Headers</TableCell>
                                    <TableCell>$16.95</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lithium Ion Polymer Battery - 3.7v 6600mAh</TableCell>
                                    <TableCell>$29.50</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>RioRand 3m Waterproof Digital Temperature Sensor x5</TableCell>
                                    <TableCell>$16.99</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>4.7kΩ resistor, mini breadboard, wires, zipties</TableCell>
                                    <TableCell>Negligible</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>

                    <p>Rough total for construction was <strong>$55</strong> with some temperature sensors left over.</p>

                    <p>My only change to the above list would be an ESP8266 with soldered headers. Having loose headers provided no benefit and required soldering.</p>

                    <h3>Equipment used</h3>
                    <p>A list of equipment used to construct the sensor</p>
                    <div className={ styles.tableContainer }>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Component</TableCell>
                                    <TableCell>Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Anbes Soldering Iron Kit</TableCell>
                                    <TableCell>$25.00</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>IRWIN VISE-GRIP Wire Stripping Tool / Wire Cutter</TableCell>
                                    <TableCell>$12.35</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>AstroAI Digital Multimeter, TRMS 6000</TableCell>
                                    <TableCell>$34.99</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Adafruit Mini Lipo w/Mini-B USB Jack - USB LiIon/LiPoly charger</TableCell>
                                    <TableCell>$6.95</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </InfoExpando>
            </InfoSection>


            <InfoSection>
                <InfoExpando title="Sensor code">
                    <p>The general flow of code is as follows:</p>
                    <ol>
                        <li>Read RTC memory for variables saved across deep sleep</li>
                        <li>Connect to wifi (if no connection, save RTC memory and go back to sleep for 15 minutes)</li>
                        <li>Read the temperatures from the sensors and from the OpenWeather api, then create a new record in the lake temps db</li>
                        <li>Use RTC memory and some system info to update the current run record in the sensor info db</li>
                        <li>Write the RTC memory</li>
                        <li>Go to sleep for 15 minutes</li>
                        <li>RST=>GPIO16 high - go back to step 1</li>
                    </ol>

                    <HardwareCode />

                    <p>You may notice that there is no loop function. This is unnecessary with the deep sleep pattern. When the board wakes up, it launches the setup function, and at the end of the setup it is put back into deep sleep.</p>
                </InfoExpando>
            </InfoSection>


            <InfoSection>
                <InfoExpando title="UI">
                    <p>
                        The UI was built with React using AWS Amplify and styled using React JSS and Material UI.
                        Everything herein was rather routine, so I won't elaborate on the design choices except to say that I prefer Emotion to React JSS thus far.
                    </p>
                </InfoExpando>
            </InfoSection>


            <InfoSection>
                <InfoExpando title="API">
                    <p>
                        The backend was built with an AWS Amplify graphQL implementation.
                        I found the AWS Amplify terminal to be a very convenient consolidation of resources and tools, however, the documentation on certain things, especially graphQL, was lacking.
                        There is more detail on the hang-ups I faced in <Link to={ Pages[pageKeys.lakeTempLearnings]?.route }>the learnings section</Link>.
                    </p>

                    <h3>LakeTemp schema</h3>
                    <p>
                        Initially, this was built as a dynamoDB table. I later added @searchable, which puts the model in Elasticsearch, to have flexibility in the future.
                        The current iteration would probably be feasible without @searchable, however, I don't have too much data to manage, so the ease of use was worth it.
                    </p>
                    <SyntaxHighlighter language="graphql" style={ dark }>
                        { `type LakeTemp @model @auth(rules: [{ allow: public }]) @searchable {
  id: ID!
  createdAt: AWSDateTime
  runVersion: String! # Could connect to SensorInfo, but no real reason in this version
  deepTempF: Float!
  deepTempC: Float!
  shallowTempF: Float!
  shallowTempC: Float!
  airTempF: Float
  airTempC: Float
  cloudCover: Float
  windSpeed: Float
  windGust: Float
}` }
                    </SyntaxHighlighter>

                    <h3>SensorInfo schema</h3>
                    <p>
                        This model is rather straightforward. The only notable change is the use of @key, which allows me to easily search for, and update, records by runVersion.
                    </p>
                    <SyntaxHighlighter language="graphql" style={ dark }>
                        { `type SensorInfo @model @auth(rules: [{ allow: public }]) @key (
  fields: ["runVersion"]
) {
  createdAt: AWSDateTime
  runVersion: String!
  batteryVoltage: Float!
  batterymAh: Int!
  counter: Int!
  totalCycleTime: Int!
  voltage: Float!
}` }
                    </SyntaxHighlighter>
                </InfoExpando>
            </InfoSection>


            <InfoSection>
                <InfoExpando defaultExpanded title="Future improvements">
                    { renderGoalList(futureObjectives) }
                </InfoExpando>
            </InfoSection>
        </InfoContainer>
    );
};

export default LakeAbout;
