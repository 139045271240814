import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import LeftNavigationLink from './LeftNavigationLink.jsx';
import AppConstants from '../../AppConstants';
import { LeftNavigationItems } from './Navigation';

const useStyles = createUseStyles(theme => ({
    container: {
        height: '100%',
        zIndex: 99,
        flex: '0 0 10rem',
        [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
            display: 'none'
        }
    },
    fixedContainer: {
        height: '100%',
        position: 'fixed',
        background: theme.colors.leftNavigationBackground,
        width: '10rem',
        minWidth: '10rem'
    }
}));

const LeftNavigation = () => {
    const styles = useStyles(useTheme());

    return (
        <div className={ styles.container }>
            <div className={ styles.fixedContainer }>
                { LeftNavigationItems.map(navItem => (
                    <LeftNavigationLink key={ `left-nav-item-${ navItem }` } pageKey={ navItem } />
                )) }
            </div>
        </div>
    );
};

export default LeftNavigation;
