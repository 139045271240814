import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Legend } from 'recharts';
import RadioButtonBar from './RadioButtonBar';

import Strings from '../translations/strings/en';

import Card from './Card.jsx';
import { createUseStyles, useTheme } from 'react-jss';
import AppConstants from '../AppConstants';
import { dataMemo, intervalFilters } from '../utils/graphAPI';
import GraphLoadingIcons from './GraphLoadingIcons';

const useStyles = createUseStyles(theme => ({
    legendLabel: {
        fontWeight: theme.typography.semiBold,
        color: theme.colors.specialCase.smoke,
        cursor: 'pointer'
    },
    activeLegendLabel: {
        color: theme.colors.darkText
    },
    loadingContainer: {
        position: 'absolute',
        /* Note that these rems are guesses. If I wanted to, I could pull the exact height / width of the card header / y axis */
        top: 'calc(50% - 2rem)',
        left: 'calc(50% + 1rem)',
        transform: 'translate(-50%, -50%)'
    }
}));
const GraphCard = ({ dataTypes, maxValue, minValue, rawData, title, unit }) => {
    const styles = useStyles(useTheme());
    const [activeTypes, setActiveTypes] = useState(Object.entries(dataTypes).reduce((acc, [type, config]) => ({ ...acc, [type]: config.isActive }), {}));
    const latestTime = moment(rawData[rawData.length - 1]?.createdAt)
    const totalIntervalMS = moment(rawData[0]?.createdAt).diff(latestTime);
    const [interval, setInterval] = useState(AppConstants.graphIntervals.TODAY);
    const graphContainerEl = useRef(null);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    const handleResize = () => {
        const graphWidth = graphContainerEl?.current?.clientWidth || 600;
        const graphHeight = Math.max(graphWidth * 0.2, 400);

        setDimensions({
            height: graphHeight,
            width: graphWidth
        });
    };
    // Resize the graph when the component mounts
    useEffect(() => {
        handleResize();
    }, [graphContainerEl]);
    // TODO debounce
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // Set the interval to THREE_DAYS if the user has more than one day worth of data
    useEffect(() => {
        const todayInterval = latestTime.diff(latestTime.clone().startOf('day'));
        setInterval(
            totalIntervalMS > todayInterval
                ? AppConstants.graphIntervals.THREE_DAYS
                : AppConstants.graphIntervals.TODAY
        );
    }, [totalIntervalMS]);

    // Filter values to only have a renderable number of data points from within the interval
    const data = useMemo(() => dataMemo(rawData, interval), [interval, rawData]); // Not sure why my IDE complains about this... seems to work. Alternatively can have interval set to none until data.

    const curTime = (new Date())?.getTime();

    return (
        <Card
            title={ title }
            titleRight={
                <RadioButtonBar
                    onChange={ setInterval }
                    options={ Object.keys(AppConstants.graphIntervals).map(interval => ({
                        value: interval,
                        label: Strings.graphIntervalLabels[interval],
                        // Disable the interval if the interval exceeds the data
                        disabled: (
                            !totalIntervalMS
                            || (
                                totalIntervalMS < intervalFilters[interval].intervalMS
                                && interval !== AppConstants.graphIntervals.TODAY
                            )
                        )
                    })) }
                    value={ totalIntervalMS && interval }
                />
            }
        >
            { /* This container height and absolute positioned div are necessary to make the downsizing work */ }
            <div ref={ graphContainerEl } style={ { height: dimensions?.height } }>
                <div style={ { position: 'absolute' } }>
                    { (!data || !data.length) && (
                        // There's a possibility that this could have been done with css keyframes + animations, but this is simpler
                        <div className={ styles.loadingContainer }>
                            <GraphLoadingIcons />
                        </div>
                    ) }
                    <LineChart data={ data } height={ dimensions?.height } width={ dimensions?.width }>
                        <XAxis
                            dataKey="createdAt"
                            reversed
                            tickFormatter={ time => (time && time !== 'auto') ? moment(time).format('M/DD[@]HH:mm') : '' }
                        />
                        <YAxis
                            allowDecimals={ false }
                            domain={ [dataMin => Math.max(minValue || -Infinity, Math.round(dataMin) - 5), dataMax => Math.min(maxValue || Infinity, Math.round(dataMax) + 5)] }
                            tickCount={ 6 }
                            tickFormatter={ value => value?.toFixed(1) }
                            unit={ unit }
                        />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        { Object.entries(dataTypes).map(([type, config]) => (
                            <Line
                                dataKey={ type }
                                hide={ !activeTypes[type] }
                                key={ `graph-line-${ type }` }
                                stroke={ config.color }
                                type="monotone"
                            />
                        )) }
                        { Object.keys(dataTypes)?.length > 1 && (
                            <Legend
                                formatter={ value => (
                                    <span className={ [styles.legendLabel, activeTypes[value] ? styles.activeLegendLabel : ''].join(' ') }>
                                        { Strings.graphLabels[value] }
                                    </span>
                                ) }
                                onClick={ ({ dataKey }) => setActiveTypes(prevActive => ({ ...prevActive, [dataKey]: !prevActive[dataKey] })) }
                            />
                        ) }
                        <Tooltip
                            formatter={ (value, name) => (
                                [`${ value?.toFixed(1) }°`, Strings.graphLabels[name]]
                            ) }
                            labelFormatter={ time => `${ moment(time).format('MMM D, Y') } at ${ moment(time).format('h:mm:ss a') }` }
                        />
                        <div>Hello there</div>
                    </LineChart>
                </div>
            </div>
        </Card>
    );
};

GraphCard.propTypes = {
    dataTypes: PropTypes.object,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    rawData: PropTypes.array,
    title: PropTypes.string,
    unit: PropTypes.string
};

GraphCard.defaultProps = {
    rawData: []
};

export default GraphCard;
