import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const useStyles = createUseStyles({
    expando: {
        width: '100%'
    },
    expandoContent: {
        padding: '0 2rem 1rem 2rem'
    }
});

const InfoSection = ({ children, defaultExpanded, title }) => {
    const styles = useStyles();

    return (
        <Accordion className={ styles.expando } defaultExpanded={ defaultExpanded }>
            <AccordionSummary expandIcon={ <ExpandMore /> }>
                <h2>{ title }</h2>
            </AccordionSummary>
            <AccordionDetails>
                <div className={ styles.expandoContent }>
                    { children }
                </div>
            </AccordionDetails>
        </Accordion>

    );
};

InfoSection.propTypes = {
    children: PropTypes.node,
    defaultExpanded: PropTypes.bool,
    title: PropTypes.string
};

export default InfoSection;
