import React from 'react';
import AppHeader from './modules/AppHeader.jsx';
import AppBody from './modules/AppBody.jsx';
import { ThemeProvider } from 'react-jss';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import Theme from './Theme.js';
import MuiTheme from './MuiTheme';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

const App = () => (
    <ThemeProvider theme={ Theme }>
      <MuiThemeProvider theme={ MuiTheme }>
        <Router>
          <AppHeader />
          <AppBody />
        </Router>
      </MuiThemeProvider>
    </ThemeProvider>
);

export default App;
