import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardMedia, CardContent, CardActions } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createUseStyles, useTheme } from 'react-jss';
import Strings from '../../translations/strings/en';
import AppConstants from "../../AppConstants";

const useStyles = createUseStyles(theme => ({
    title: {
        fontSize: theme.sizes.xxxl
    },
    subTitle: {
        paddingTop: '1rem',
        color: theme.colors.mediumText
    }
}));
const useClasses = makeStyles({
    cardRoot: {
        width: '27rem',
        margin: '0 4rem 2rem',
        // Make the side margins a bit narrower for mobile
        [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
            margin: '0 1rem 2rem'
        }
    },
    media: {
        height: '20rem'
    }
});
const ProjectCard = ({ image, imageTitle, link, subTitle, title }) => {
    const classes = useClasses();
    const styles = useStyles(useTheme());

    return (
        <Card classes={ { root: classes.cardRoot } }>
            <CardMedia classes={ { root: classes.media } } image={ image } title={ imageTitle } />
            <CardContent>
                <div className={ styles.title }>{ title }</div>
                <div className={ styles.subTitle }>{ subTitle }</div>
            </CardContent>
            <CardActions>
                <Button color="primary" href={ link } size="small">
                    { Strings.splash.viewProject }
                </Button>
            </CardActions>
        </Card>
    );
};

ProjectCard.propTypes = {
    image: PropTypes.string,
    imageTitle: PropTypes.string,
    link: PropTypes.string,
    subTitle: PropTypes.string,
    title: PropTypes.string
};

export default ProjectCard;
