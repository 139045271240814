import React from 'react';
import PropTypes from 'prop-types';
import GraphCard from '../../../components/GraphCard';
import AppConstants from '../../../AppConstants';
import Theme from '../../../Theme';

const TempGraph = ({ data }) => (
    <GraphCard
        dataTypes={ {
            [AppConstants.lakeGraphDataTypes.airTempF]: {
                isActive: false,
                color: Theme.colors.specialCase.smoke
            },
            [AppConstants.lakeGraphDataTypes.shallowTempF]: {
                isActive: true,
                color: Theme.colors.alpha
            },
            [AppConstants.lakeGraphDataTypes.deepTempF]: {
                isActive: true,
                color: Theme.colors.echo
            }
        } }
        rawData={ data }
        title="Temperature vs Time"
        unit=" °F"
    />
);

TempGraph.propTypes = {
    data: PropTypes.array
};

export default TempGraph;
