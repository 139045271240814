import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { searchLakeTemps } from '../../../graphql/queries.js';
import moment from 'moment';
import AppConstants from '../../../AppConstants';

import Card from '../../../components/Card.jsx';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: `calc(100% - ${ AppConstants.headerHeight })`
    },
    dataContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '1rem',
        flexWrap: 'wrap'
    },
    dataBlock: {
        textAlign: 'center',
        flex: '1 0 30%',
        margin: '0.5rem'
    },
    data: {
        display: 'inline-block',
        padding: '0.5rem',
        borderRadius: '4px',
        '@media(min-width: 75rem)': {
            padding: '1rem'
        }

    },
    shallow: {
        backgroundColor: theme.colors.charlie
    },
    deep: {
        backgroundColor: theme.colors.echo,
        color: theme.colors.lightText
    },
    air: {
        backgroundColor: theme.colors.specialCase.haze,
        color: theme.colors.darkText
    },
    wind: {
        backgroundColor: theme.colors.specialCase.iceSaberLight,
        color: theme.colors.darkText
    },
    cloud: {
        backgroundColor: theme.colors.specialCase.smoke,
        color: theme.colors.darkText
    },
    dataLabel: {
        fontSize: theme.sizes.xxl
    },
    bigData: {
        fontSize: theme.sizes.xxxl
    },
    italic: {
        fontStyle: 'italic'
    }
}));

const CurrentTempsCard = () => {
    const styles = useStyles(useTheme());
    const [curReadings, setCurReadings] = useState({});

    // Fetch data on mount
    useEffect(() => {
        // Fetch the current data
        API.graphql(graphqlOperation(
            searchLakeTemps,
            { sort: { field: 'createdAt', direction: 'desc' }, limit: 1 }
        )).then((response) => {
            setCurReadings(response?.data?.searchLakeTemps?.items?.[0] || {});
        });
    }, []);

    const createdAt = curReadings.createdAt;
    const createdAtString = createdAt ? `${ moment(createdAt).format('MMM D, Y') } at ${ moment(createdAt).format('h:mm:ss a') }` : '--';

    return (
        <Card title="Current" titleRight={ <span className={ styles.italic }>{ createdAtString }</span> }>
            <div className={ styles.container }>
                <div className={ styles.dataContainer }>
                    <div className={ styles.dataBlock }>
                        <div className={ [styles.data, styles.shallow].join(' ') }>
                            <div className={ styles.dataLabel }>Shallow (1ft)</div>
                            <div className={ styles.bigData }>{ curReadings.shallowTempF?.toFixed(1) || '--' }&#176;</div>
                        </div>
                    </div>
                    <div className={ styles.dataBlock }>
                        <div className={ [styles.data, styles.deep].join(' ') }>
                            <div className={ styles.dataLabel }>Deep (4ft)</div>
                            <div className={ styles.bigData }>{ curReadings.deepTempF?.toFixed(1) || '--' }&#176;</div>
                        </div>
                    </div>
                    <div className={ styles.dataBlock }>
                        <div className={ [styles.data, styles.air].join(' ') }>
                            <div className={ styles.dataLabel }>Air *</div>
                            <div className={ styles.bigData }>{ curReadings.airTempF?.toFixed(1) || '--' }&#176;</div>
                        </div>
                    </div>
                    <div className={ styles.dataBlock }>
                        <div className={ [styles.data, styles.wind].join(' ') }>
                            <div className={ styles.dataLabel }>Wind speed *</div>
                            <div className={ styles.bigData }>{ curReadings.windSpeed?.toFixed(1) || '--' } mph</div>
                        </div>
                    </div>
                    <div className={ styles.dataBlock }>
                        <div className={ [styles.data, styles.cloud].join(' ') }>
                            <div className={ styles.dataLabel }>Cloud cover *</div>
                            <div className={ styles.bigData }>{ curReadings.cloudCover?.toFixed(1) || '--' }%</div>
                        </div>
                    </div>
                </div>
                <div className={ styles.italic }>* Data from weather forecast</div>
            </div>
        </Card>
    );
};

export default CurrentTempsCard;
