import React, { useEffect, useState } from 'react';

import { createUseStyles } from 'react-jss';
import CurrentTempsCard from './cards/CurrentTempsCard';
import SensorInfo from './cards/SensorInfo';
import TempGraph from './cards/TempGraph';

import { getData } from '../../utils/graphAPI';
import { searchLakeTemps } from '../../graphql/queries.js';
import AppConstants from '../../AppConstants';

const useStyles = createUseStyles({
    cardRow: {
        display: 'flex',
        flexWrap: 'wrap'
    }
});

const LakeData = () => {
    const styles = useStyles();
    const [data, setData] = useState([]);

    // Fetch data on mount
    useEffect(() => {
        (async () => setData(await getData(searchLakeTemps, 'searchLakeTemps', AppConstants.lakeRunVersion)))();
    }, []);

    return (
        <div>
            <div className={ styles.cardRow }>
                <CurrentTempsCard />
                <SensorInfo />
            </div>
            <div className={ styles.cardRow }>
                <TempGraph data={ data } />
            </div>
        </div>
    );
};

export default LakeData;
