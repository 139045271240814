import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import GraphCard from '../../components/GraphCard';
import { searchBeehiveMonitors } from '../../graphql/queries';
import AppConstants from '../../AppConstants';
import Theme from '../../Theme';
import { getData } from '../../utils/graphAPI';

const useStyles = createUseStyles({
    cardRow: {
        display: 'flex',
        flexWrap: 'wrap'
    }
});
const BeehiveMonitorData = () => {
    const styles = useStyles();
    const [data, setData] = useState([]);

    // Fetch data on mount
    useEffect( () => {
        (async () => setData(await getData(searchBeehiveMonitors, 'searchBeehiveMonitors', AppConstants.beehiveRunVersion)))();
    }, []);

    return (
        <div>
            <div className={ styles.cardRow }>
                <GraphCard
                    dataTypes={ {
                        [AppConstants.humiditySensorDataTypes.temperature]: {
                            isActive: true,
                            color: Theme.colors.alpha
                        },
                        [AppConstants.humiditySensorDataTypes.heatIndex]: {
                            isActive: false,
                            color: Theme.colors.specialCase.orange
                        }
                    } }
                    rawData={ data }
                    title="Temperature vs Time"
                    unit=" °F"
                />
                <GraphCard
                    dataTypes={ {
                        [AppConstants.humiditySensorDataTypes.humidity]: {
                            isActive: true,
                            color: Theme.colors.specialCase.smoke
                        }
                    } }
                    maxValue={ 100 }
                    rawData={ data }
                    title="Humidity vs Time"
                    unit="%"
                />
            </div>
        </div>
    );
};

export default BeehiveMonitorData;
