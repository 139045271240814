import React  from 'react';
import Pages, { pageKeys } from '../../Pages';
import { createUseStyles, useTheme } from 'react-jss';
import { colors } from '../../Theme';
import HeaderMenuComputer from './HeaderMenuComputer';
import HeaderMenuMobile from './HeaderMenuMobile';

const useStyles = createUseStyles(theme => ({
    headerNavigation: {
        paddingRight: '0.5rem'
    }
}));
const HeaderNavigation = () => {
    const styles = useStyles(useTheme());

    return (
        <div className={ styles.headerNavigation }>
            <HeaderMenuComputer />
            <HeaderMenuMobile />
        </div>
    );
};

export default HeaderNavigation;
