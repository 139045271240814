import AppConstants from '../../AppConstants';

const strings = {
    shared: {
        projects: 'Projects'
    },
    graphLabels: {
        [AppConstants.lakeGraphDataTypes.airTempF]: 'Air temp',
        [AppConstants.lakeGraphDataTypes.shallowTempF]: 'Shallow temp',
        [AppConstants.lakeGraphDataTypes.deepTempF]: 'Deep temp',
        [AppConstants.humiditySensorDataTypes.temperature]: 'Temperature',
        [AppConstants.humiditySensorDataTypes.heatIndex]: 'Heat index',
        [AppConstants.humiditySensorDataTypes.humidity]: 'Humidity'
    },
    graphIntervalLabels: {
        [AppConstants.graphIntervals.TODAY]: 'today',
        [AppConstants.graphIntervals.THREE_DAYS]: '3d',
        [AppConstants.graphIntervals.ONE_WEEK]: '1w',
        [AppConstants.graphIntervals.ONE_MONTH]: '1M',
        [AppConstants.graphIntervals.THREE_MONTHS]: '3M'
    },
    splash: {
        subtitle: 'Developing stuff and things',
        title: 'GEOFFREY LONG',
        viewProject: 'View Project'
    },
    lakeSensorV1: {
        subtitle: 'A project to track lake temperatures at depths of one and four feet.',
        title: 'Water Temp Sensor'
    },
    sailboatSensor: {
        subtitle: 'A sensor that displays boat tilt (roll) and speed; for use on a sailboat.',
        title: 'Sailboat Sensor'
    },
    beehiveSensor: {
        subtitle: 'A sensor that monitors humidity and temperatures within a beehive.',
        title: 'Beehive Monitor'
    }
};

export default strings;
