import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import { Tab, Tabs } from '@mui/material';
import { withRouter } from 'react-router-dom';

// TODO make the tabs (learning, about, etc) standardized, since this is probably somethihng I'll do for many projects
const useStyles = createUseStyles(theme => ({
    container: {
        padding: '1rem',
        background: theme.colors.headerBackground,
        boxShadow: `0px 3px 10px ${ theme.colors.headerShadow }`,
        marginBottom: '0.5rem'
    },
    title: {
        fontSize: theme.sizes.xxl
    },
    subtitle: {
        color: theme.colors.mediumLightText,
        fontStyle: 'italic'
    },
    tabRow: {
        marginBottom: '-1rem',
        marginTop: '0.5rem'
    }
}));

const PageHeader = ({ children, history, location, subtitle, tabs, title }) => {
    const styles = useStyles(useTheme());

    return (
        <div className={ styles.container }>
            <div className={ styles.title }>{ title }</div>
            <div className={ styles.subtitle }>{ subtitle }</div>
            { children }
            { tabs && (
                <div className={ styles.tabRow }>
                    <Tabs
                        indicatorColor="primary"
                        onChange={ (e, value) => history.push(value) }
                        textColor="primary"
                        value={ location.pathname }
                    >
                        { tabs.map(tab => <Tab key={ `page-header-tab-${ tab.value }` } label={ tab.label } value={ tab.value } />) }
                    </Tabs>
                </div>
            ) }
        </div>
    );
};

PageHeader.propTypes = {
    children: PropTypes.node,
    history: PropTypes.object,
    location: PropTypes.object,
    subtitle: PropTypes.string,
    tabs: PropTypes.array,
    title: PropTypes.string
};

export default withRouter(PageHeader);
