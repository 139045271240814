import { createTheme, adaptV4Theme } from '@mui/material/styles';


const theme = createTheme(adaptV4Theme({
    overrides: {
        MuiTable: {
            root: {
                width: 'inherit'
            }
        },
        MuiTableCell: {
            head: {
                fontWeight: 'bold'
            }
        },
        // Take care of that pesky shifting mui summary
        MuiExpansionPanel: {
            root: {
                '&$expanded': {
                    minHeight: 0
                }
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                '&$expanded': {
                    minHeight: 0
                }
            },
            content: {
                '&$expanded': {
                    margin: 0
                },
                margin: 0
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                display: 'block',
                padding: 0
            }
        }
    }
}));

export default theme;
