const AppConstants = {
    mobileWidth: '50rem',
    headerHeight: '2.25rem',
    lakeRunVersion: 'run3',
    beehiveRunVersion: 'run1',
    houseHumidityRunVersion: 'run1',
    graphqlLimit: 1000,
    graphIntervals: {
        TODAY: 'TODAY',
        THREE_DAYS: 'THREE_DAYS',
        ONE_WEEK: 'ONE_WEEK',
        ONE_MONTH: 'ONE_MONTH',
        THREE_MONTHS: 'THREE_MONTHS'
    },
    lakeGraphDataTypes: {
        airTempF: 'airTempF',
        shallowTempF: 'shallowTempF',
        deepTempF: 'deepTempF'
    },
    humiditySensorDataTypes: {
        temperature: 'temperature',
        heatIndex: 'heatIndex',
        humidity: 'humidity'
    }
};

export default AppConstants;
