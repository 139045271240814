import { DeviceThermostat, EmojiNature, Home, Sailing } from '@mui/icons-material';

export const pageKeys = {
    splash: 'splash',
    projects: 'projects',
    lakeTemps: 'lakeTemps',
    lakeTempAbout: 'lakeTempAbout',
    lakeTempLearnings: 'lakeTempLearnings',
    sailboatSensor: 'sailboatSensor',
    sailboatSensorLearnings: 'sailboatSensorLearnings',
    beehiveMonitor: 'beehiveMonitor',
    beehiveMonitorAbout: 'beehiveMonitorAbout',
    beehiveMonitorLearnings: 'beehiveMonitorLearnings',
    houseHumidity: 'houseHumidity',
    houseHumidityAbout: 'houseHumidityAbout',
    houseHumidityLearnings: 'houseHumidityLearnings'
};

const Pages = {
    [pageKeys.splash]: {
        route: '/',
        title: 'Home',
        icon: <Home />
    },
    [pageKeys.projects]: {
        route: '/projects',
        title: 'Projects',
        redirect: '/projects/lake-temps'
    },
    [pageKeys.lakeTemps]: {
        route: '/projects/lake-temps',
        title: 'Lake Temps',
        icon: <DeviceThermostat />
    },
    [pageKeys.lakeTempAbout]: {
        route: '/projects/lake-temps/about'
    },
    [pageKeys.lakeTempLearnings]: {
        route: '/projects/lake-temps/learnings'
    },
    [pageKeys.sailboatSensor]: {
        route: '/projects/sailboat-sensor',
        title: 'Sailboat Sensor',
        icon: <Sailing />
    },
    [pageKeys.sailboatSensorLearnings]: {
        route: '/projects/sailboat-sensor/learnings'
    },
    [pageKeys.beehiveMonitor]: {
        route: '/projects/beehive-monitor',
        title: 'Beehive Monitor',
        icon: <EmojiNature />
    },
    [pageKeys.beehiveMonitorAbout]: {
        route: '/projects/beehive-monitor/about'
    },
    [pageKeys.beehiveMonitorLearnings]: {
        route: '/projects/beehive-monitor/learnings'
    },
    [pageKeys.houseHumidity]: {
        route: '/projects/house-humidity',
        title: 'House Humidity'
    },
    [pageKeys.houseHumidityAbout]: {
        route: '/projects/house-humidity/about'
    },
    [pageKeys.houseHumidityLearnings]: {
        route: '/projects/house-humidity/learnings'
    }
};

export default Pages;
