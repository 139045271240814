/**
Learnings
 - Bought disposable 9v batteries when I should have gotten rechargeable ones
 - Could not connect to sensor => turned out to be a faulty wire. The wire was in, but at certain angles, it wouldn't provide any power. This makes me very nervous for future circuits as it will be hard to diagnose a wire that is loose in this manner.
 - Weird timing with GPS (worked with prints, but not without (outputted like 30 readings at once))
 - That weird thing with the dtostrf function overwriting the speed value
 - Book said connect V0 to +5 via resistor, but it should actually be connected to ground via resistor
 - My GPS Rx and Tx pins may be flipped
 */

/**
 * TODO
 * Add link for "learnings section" text
 * Add images for the sensor impl
 * Add cost
 */

import React from 'react';

import PageHeader from '../../components/PageHeader.jsx';
import SailboatAbout from './SailboatAbout.jsx';
import SailboatLearnings from './SailboatLearnings.jsx';
import { Route, Switch } from 'react-router-dom';
import Pages, { pageKeys } from '../../Pages.js';

const sailboatSensorTabs = {
    ABOUT: Pages[pageKeys.sailboatSensor]?.route,
    LEARNINGS: Pages[pageKeys.sailboatSensorLearnings]?.route
};
const LakeTemps = () => (
    <div>
        <PageHeader
            subtitle="A fun sensor that displays boat tilt (roll) and speed; for use on a sailboat."
            tabs={ [{
                label: 'About',
                value: sailboatSensorTabs.ABOUT
            }, {
                label: 'Learnings',
                value: sailboatSensorTabs.LEARNINGS
            }] }
            title="Sailboat Sensor"
        />
        <Switch>
            <Route path={ sailboatSensorTabs.LEARNINGS }>
                <SailboatLearnings />
            </Route>
            <Route path={ sailboatSensorTabs.ABOUT }>
                <SailboatAbout />
            </Route>
        </Switch>
    </div>
);

export default LakeTemps;

