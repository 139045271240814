import React  from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import HeaderNavigation from './navigation/HeaderNavigation';
import AppConstants from '../AppConstants';

const useStyles = createUseStyles(theme => ({
    container: {
        position: 'fixed',
        background: theme.colors.alpha,
        height: AppConstants.headerHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 99,
        fontWeight: 'bold',
        color: theme.colors.lightText,
        fontSize: theme.sizes.md
    },
    appTitle: {
        paddingLeft: '0.75rem',
        fontSize: theme.sizes.lg
    }
}));

const AppHeader = () => {
    const styles = useStyles(useTheme());

    return (
        <div className={ styles.container }>
            <h1 className={ styles.appTitle }>
                GeoffreyLong.io
            </h1>
            <HeaderNavigation />
        </div>
    );
};

export default AppHeader;
