import React from 'react';
import Learnings from '../../components/Learnings';

/**
Learnings
 - Bought disposable 9v batteries when I should have gotten rechargeable ones
 - Could not connect to sensor => turned out to be a faulty wire. The wire was in, but at certain angles, it wouldn't provide any power. This makes me very nervous for future circuits as it will be hard to diagnose a wire that is loose in this manner.
 - Weird timing with GPS (worked with prints, but not without (outputted like 30 readings at once))
 - That weird thing with the dtostrf function overwriting the speed value
 */

const learnings = [
    {
        title: 'MPU6050 overflow issues',
        icon: '',
        symptoms: 'The MPU was constantly overflowing the digital motion processor\'s buffer.',
        reason: 'This is a quirk of the chip which requires that the processing buffer be read and cleared out constantly.',
        solution: 'I switched to an interrupt driven model, which required an interrupt pin be used. This reduced the probability of an overflow to almost zero.'
    },
    {
        title: 'MPU6050 doesn\'t read properly at certain angles',
        icon: '',
        symptoms: 'If the MPU is mounted sideways or upside-down, the readings are off.',
        reason: 'The MPU has specific expected orientation required to work correctly.',
        solution: 'The enclosure needed to be configured such that the MPU was sitting face up, perpendicular to gravity. Perhaps a 9-axis MPU would have been better suited to the task.'
    },
    {
        title: 'Could not connect to MPU',
        icon: '',
        symptoms: 'The MPU was not transmitting motion data.',
        reason: 'This turned out to be a faulty wire, which would read when pressed up against the breadboard, but not when directly inserted.',
        solution: 'The wire was replaced, but this issue could re-appear with later circuits. Remember to always check your connections!'
    },
    {
        title: 'Cannot flip an lcd 1602a',
        icon: '',
        symptoms: 'The lcd was printing upside-down in my initial configuration.',
        reason: 'The lcd only supports one orientation.',
        solution: 'I could have used a character set with flipped letters, but the easier solution here was to reconfigure the circuit so that the orientation was correct.'
    },
    {
        title: 'Incorrect sensor diagrams',
        icon: '',
        symptoms: 'GPS was not properly transmitting data and the LCD wasn\'t displaying correctly.',
        reason: 'Some documentation for the LCD and the GPS was incorrect for my particular components.',
        solution: 'Through trial and error, I discovered that my GPS Rx and Tx are flipped and that the lcd\'s V0 pin needed to be connected to ground instead of +5.'
    }
];
const SailboatLearnings = () => (
    <Learnings learnings={ learnings } />
);

export default SailboatLearnings;
