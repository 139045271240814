import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h2': {
            margin: 0
        }
    }
});

const InfoContainer = ({ children }) => {
    const styles = useStyles();

    return (
        <div className={ styles.container }>
            { children }
        </div>
    );
};

InfoContainer.propTypes = {
    children: PropTypes.node
};

export default InfoContainer;
