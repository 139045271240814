import React from 'react';

const BeehiveMonitorAbout = () => {

    return (
        <div>About</div>
    );
};

export default BeehiveMonitorAbout;
