/**
 * TODO nexties
 *      Add the validation stuff to the sensor API request
 *
 * TODO future improvements
 *
 *
 * NEW PROGRAM FLOW
 * Connected to raspberry pi
 *      5v battery pack (need to buy)
 *      Anemometer
 *      Camera
 *      Old cable from DS18B20 (connected to the esp8266
 *
 * Connected to 8266
 *      Temperature sensor (2?)
 *      Cable from raspberry pi
 *
 * Flow
 *      Raspberry pi wakes up
 *      Raspberry pi polls the camera
 *      Raspberry pi polls the anemometer
 *      Raspberry pi wakes up esp8266
 *      Raspberry pi sends data to esp8266
 *      Raspberry pi sleeps
 *      Esp8266 polls the DS18B20
 *      Esp8266 sends data to server
 *      Esp8266 sleeps
 */


import React from 'react';

import PageHeader from '../../components/PageHeader.jsx';
import LakeData from './LakeData.jsx';
import LakeAbout from './LakeAbout.jsx';
import LakeLearnings from './LakeLearnings.jsx';
import { Route, Switch } from 'react-router-dom';
import Pages, { pageKeys } from '../../Pages.js';

const lakeTempTabs = {
    DATA: Pages[pageKeys.lakeTemps]?.route,
    ABOUT: Pages[pageKeys.lakeTempAbout]?.route,
    LEARNINGS: Pages[pageKeys.lakeTempLearnings]?.route
};
const LakeTemps = () => (
    <div>
        <PageHeader
            subtitle="A project to track lake temperatures at depths of one and four feet."
            tabs={ [{
                label: 'Data',
                value: lakeTempTabs.DATA
            }, {
                label: 'About',
                value: lakeTempTabs.ABOUT
            }, {
                label: 'Learnings',
                value: lakeTempTabs.LEARNINGS
            }] }
            title="Merrymeeting Lake Temperatures"
        />
        <Switch>
            <Route path={ lakeTempTabs.ABOUT }>
                <LakeAbout />
            </Route>
            <Route path={ lakeTempTabs.LEARNINGS }>
                <LakeLearnings />
            </Route>
            <Route path={ lakeTempTabs.DATA }>
                <LakeData />
            </Route>
        </Switch>
    </div>
);

export default LakeTemps;
