import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import AppConstants from '../AppConstants';

const useStyles = createUseStyles(theme => ({
    section: {
        background: theme.colors.specialCase.almostWhite,
        margin: '1rem 0',
        width: '75vw',
        maxWidth: '60rem',
        [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
            width: '95vw'
        }
    }
}));

const InfoSection = ({ children }) => {
    const styles = useStyles(useTheme());

    return (
        <div className={ styles.container }>
            <div className={ styles.section }>
                { children }
            </div>
        </div>
    );
};

InfoSection.propTypes = {
    children: PropTypes.node
};

export default InfoSection;
