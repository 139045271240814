/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchLakeTemps = /* GraphQL */ `
  query SearchLakeTemps(
    $filter: SearchableLakeTempFilterInput
    $sort: [SearchableLakeTempSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableLakeTempAggregationInput]
  ) {
    searchLakeTemps(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        updatedAt
        runVersion
        deepTempF
        deepTempC
        shallowTempF
        shallowTempC
        airTempF
        airTempC
        cloudCover
        windSpeed
        windGust
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchBeehiveMonitors = /* GraphQL */ `
  query SearchBeehiveMonitors(
    $filter: SearchableBeehiveMonitorFilterInput
    $sort: [SearchableBeehiveMonitorSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBeehiveMonitorAggregationInput]
  ) {
    searchBeehiveMonitors(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        updatedAt
        runVersion
        humidity
        temperature
        heatIndex
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchBathroomHumidities = /* GraphQL */ `
  query SearchBathroomHumidities(
    $filter: SearchableBathroomHumidityFilterInput
    $sort: [SearchableBathroomHumiditySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBathroomHumidityAggregationInput]
  ) {
    searchBathroomHumidities(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        updatedAt
        runVersion
        humidity
        temperature
        heatIndex
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getLakeTemp = /* GraphQL */ `
  query GetLakeTemp($id: ID!) {
    getLakeTemp(id: $id) {
      id
      createdAt
      updatedAt
      runVersion
      deepTempF
      deepTempC
      shallowTempF
      shallowTempC
      airTempF
      airTempC
      cloudCover
      windSpeed
      windGust
    }
  }
`;
export const listLakeTemps = /* GraphQL */ `
  query ListLakeTemps(
    $filter: ModelLakeTempFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLakeTemps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        runVersion
        deepTempF
        deepTempC
        shallowTempF
        shallowTempC
        airTempF
        airTempC
        cloudCover
        windSpeed
        windGust
      }
      nextToken
    }
  }
`;
export const getSensorInfo = /* GraphQL */ `
  query GetSensorInfo($runVersion: String!) {
    getSensorInfo(runVersion: $runVersion) {
      createdAt
      updatedAt
      runVersion
      batteryVoltage
      batterymAh
      counter
      totalCycleTime
      voltage
    }
  }
`;
export const listSensorInfos = /* GraphQL */ `
  query ListSensorInfos(
    $runVersion: String
    $filter: ModelSensorInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSensorInfos(
      runVersion: $runVersion
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        updatedAt
        runVersion
        batteryVoltage
        batterymAh
        counter
        totalCycleTime
        voltage
      }
      nextToken
    }
  }
`;
export const getBeehiveMonitor = /* GraphQL */ `
  query GetBeehiveMonitor($id: ID!) {
    getBeehiveMonitor(id: $id) {
      id
      createdAt
      updatedAt
      runVersion
      humidity
      temperature
      heatIndex
    }
  }
`;
export const listBeehiveMonitors = /* GraphQL */ `
  query ListBeehiveMonitors(
    $filter: ModelBeehiveMonitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBeehiveMonitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        runVersion
        humidity
        temperature
        heatIndex
      }
      nextToken
    }
  }
`;
export const getBathroomHumidity = /* GraphQL */ `
  query GetBathroomHumidity($id: ID!) {
    getBathroomHumidity(id: $id) {
      id
      createdAt
      updatedAt
      runVersion
      humidity
      temperature
      heatIndex
    }
  }
`;
export const listBathroomHumidities = /* GraphQL */ `
  query ListBathroomHumidities(
    $filter: ModelBathroomHumidityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBathroomHumidities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        runVersion
        humidity
        temperature
        heatIndex
      }
      nextToken
    }
  }
`;
