import React from 'react';
import InfoContainer from '../../components/InfoContainer';
import InfoSection from '../../components/InfoSection';
import InfoExpando from '../../components/InfoExpando';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { createUseStyles } from 'react-jss';
import AppConstants from '../../AppConstants';
import HardwareCode from '../sailboatSensor/HardwareCode';

const useStyles = createUseStyles({
    // TODO these are copied over from LakeAbout. Should put them all somewhere
    hardwareImgs: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1rem 3rem',
        [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
            margin: '1rem 0'
        }
    },
    largeImage: {
        width: '80%',
        margin: 'auto'
    },
    img: {
        width: '40%',
        [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
            width: '49%'
        }
    },
    tableContainer: {
        marginLeft: '3rem',
        [`@media(max-width: ${ AppConstants.mobileWidth })`]: {
            marginLeft: 0
        }
    }
});
const SailboatAbout = () => {
    const styles = useStyles();

    return (
        <InfoContainer>
            <InfoSection>
                <InfoExpando defaultExpanded title="Overview">
                    <h3>Primary Objective</h3>
                    <p>
                        Create a sailboat metrics sensor to add some fun data to our sailboat rides
                    </p>

                    <h3>Summary</h3>
                    <p>
                        This is a mountable sailboat sensor that records roll (how far the boat is leaning when catching the wind) and current speed. It was built to gather some fun metrics to our sailboat rides.
                    </p>
                </InfoExpando>
            </InfoSection>


            <InfoSection>
                <InfoExpando defaultExpanded title="Hardware">
                    <h3>Hardware schematic</h3>
                    <div className={ styles.hardwareImgs }>
                        <img alt="Sailboat sensor schematic" className={ styles.largeImage } src="/img/sailboat_sensor_schematic.jpg" />
                    </div>

                    <h3>Hardware implementation</h3>
                    <div className={ styles.hardwareImgs }>
                        <img alt="Sailboat sensor circuit" className={ styles.img } src="/img/sailboat_sensor.jpg" />
                        <img alt="Sailboat sensor in use" className={ styles.img } src="/img/sailboat_sensor_outdoors.jpg" />
                    </div>

                    <h3>Hardware components</h3>
                    <p>A list of all the components that were in the final iteration of the sensor</p>
                    <div className={ styles.tableContainer }>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Component</TableCell>
                                    <TableCell>Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Weatherproof enclosure - small</TableCell>
                                    <TableCell>$9.95</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Arduino Nano V3</TableCell>
                                    <TableCell>$5.33</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>9 inch mini usb cable</TableCell>
                                    <TableCell>$3.50</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>5v battery</TableCell>
                                    <TableCell>$10.00 (est)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Neo-6m GPS module</TableCell>
                                    <TableCell>$17.99</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>MPU6050</TableCell>
                                    <TableCell>$4.99</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>LCD 1602a</TableCell>
                                    <TableCell>$10.00 (est)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>1kΩ resistor, 0Ω resistor (for space saving), 3x mini breadboard, wires</TableCell>
                                    <TableCell>Negligible</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>

                    <p>Rough total for construction was <strong>$61.76</strong></p>
                </InfoExpando>
            </InfoSection>


            <InfoSection>
                <InfoExpando title="Sensor code">
                    <p>The general flow of code is as follows:</p>
                    <ul>
                        <li>SETUP</li>
                        <ol>
                            <li>Set up the MPU (set interrupt pin, calibrate, etc), the GPS, and the LCD</li>
                        </ol>
                        <li>LOOP</li>
                        <ol>
                            <li>Get the roll value from the MPU's (motion processing unit's) DMP (digital motion processor)</li>
                            <li>Clear the MPU's memory to reduce the risk of overflow</li>
                            <li>Get the current speed from the GPS and push this speed into a queue of most recent speeds</li>
                            <li>Calculate the smoothed speed by averaging the most recent speeds</li>
                            <li>Write the roll and smoothed speeds to the LCD screen</li>
                        </ol>
                    </ul>

                    <HardwareCode />

                    <p>The code is all rather straight forward. There were some gotchas that I address in the learnings section though.</p>
                </InfoExpando>
            </InfoSection>
        </InfoContainer>
    );
};

export default SailboatAbout;
