import React from 'react';
import Learnings from '../../components/Learnings';

const learnings = [
    {
        title: 'TODO',
        icon: '',
        symptoms: 'TODO',
        reason: 'TODO',
        solution: 'TODO'
    }
];
const HouseHumidityLearnings = () => (
    <Learnings learnings={ learnings } />
);

export default HouseHumidityLearnings;
