import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Maybe could script read from the file itself then strip out the sensitive info and populate?
const HardwareCode = () => (
    <SyntaxHighlighter language="arduino" style={ dark }>
        { `// BEFORE RUN
// Change the marker flag
// Change the run version
// Change DEBUG to false
// Ensure sleep time is correct

#include <ESP8266HTTPClient.h>
#include <ESP8266WiFi.h>
#include <ArduinoJson.h>
#include <OneWire.h>
#include <DallasTemperature.h>

// To read the voltages
ADC_MODE(ADC_VCC);

// Connection variables
const char* ssid = "MY_INTERNET";
const char* password = "MY_PASSWORD";
const char* api = "API_ENDPOINT";
const char* apiKey = "API_KEY";
const char* apiSecret = "API_SECRET";
const uint8_t fingerprint[20] = {0xDD, 0x57, 0xCD, 0x2E, 0xF5, 0xDC, 0x8F, 0x40, 0x6F, 0xB0, 0xE4, 0x26, 0x7C, 0x10, 0x1A, 0xE1, 0x32, 0x28, 0x13, 0xDF};
WiFiClientSecure awsClient;

// Set up the temperature sensor
#define ONE_WIRE_BUS 4 // GPIO where the DS18B20 is connected to
OneWire oneWire(ONE_WIRE_BUS); // Setup a oneWire instance to communicate with any OneWire devices
DallasTemperature DS18B20(&oneWire); // Pass our oneWire reference to Dallas Temperature sensor
const uint8_t shallowAddress[8] = {0x28, 0x9D, 0x91, 0x26, 0x1F, 0x19, 0x01, 0xF3};
const uint8_t deepAddress[8] = {0x28, 0x33, 0x02, 0xF7, 0x1E, 0x19, 0x01, 0x60};

// Set up the rtc store for cross cycle storage
typedef struct {
  byte markerFlag;
  long totalCycleTime; // TODO convert to int
  long counter; // TODO convert to int
} rtcStore __attribute__((aligned(4))); // not sure if the aligned stuff is necessary, some posts suggest it is for RTC memory?
rtcStore rtcMem;
boolean firstRun = true;

// NOTE => VALUES TO UPDATE
// WARNING => need to update this across runs else last value could be stored
// TODO could update markerflag to be the runVersion. Semi redundant to have both.
const byte markerFlag = 127;
const char* runVersion = "run2";
const float batteryVoltage = 3.7;
const int batterymAh = 6600;
const long deepSleepUs = 18e8; // 30 minutes between polls (max is 35 minutes)

const boolean DEBUG = false;

// The setup function that sets up the connections
void setup() {
  if (DEBUG) {
    Serial.begin(115200);
    delay(100);
  }

  // Read rtc memory into program
  readRTCStore();

  // Connect to wifi
  connect();

  // Set the fingerprint for connection to the server
  awsClient.setFingerprint(fingerprint);

  // Start the DS18B20 sensor
  DS18B20.begin();

  // Send temperature readings to server
  readAndSendTemperatures();

  // Send system info to server
  readAndSendSystemInfo();

  // Clean up and put into deep sleep for 15 minutes
  WiFi.disconnect();
  writeRTCStore();
  ESP.deepSleep(deepSleepUs);
}

void readRTCStore() {
  system_rtc_mem_read(65, &rtcMem, sizeof(rtcMem));

  // If the marker flag isn't set, then we are in the first run
  if (rtcMem.markerFlag != markerFlag) {
    rtcMem.markerFlag = markerFlag;
    rtcMem.counter = 0;
    rtcMem.totalCycleTime = 0;
  } else {
    firstRun = false;
  }
}

void writeRTCStore() {
  rtcMem.totalCycleTime += millis();
  rtcMem.counter += 1;
  system_rtc_mem_write(65, &rtcMem, sizeof(rtcMem));
}

void connect() {
  // Start the wifi connection
  WiFi.begin(ssid, password);
  int counter = 0;

  // Spin while connecting
  while (WiFi.status() != WL_CONNECTED) {
    delay(2000);
    counter++;
    if (DEBUG) Serial.print(counter);

    // If we haven't connected within 120 seconds, update the run logs and skip this cycle
    if (counter >= 60) {
      writeRTCStore();
      ESP.deepSleep(deepSleepUs);
    }
  }

  if (DEBUG) Serial.println("connected");
}

void readAndSendTemperatures() {
  DynamicJsonDocument doc(1024);
  String json;
  HTTPClient https;
  float temp, cloudCover, windSpeed, windGust;

  // Update sensor values
  DS18B20.requestTemperatures();

  // Get current weather data
  readCurrentWeatherData(&temp, &cloudCover, &windSpeed, &windGust);

  // Set base query in json doc (fetched from mock post in UI)
  doc["query"] = "mutation CreateLakeTemp($input: CreateLakeTempInput!, $condition: ModelLakeTempConditionInput) {↵  createLakeTemp(input: $input, condition: $condition) {↵    id↵    createdAt↵    runVersion↵    deepTempF↵    deepTempC↵    shallowTempF↵    shallowTempC↵    airTempF↵    airTempC↵    cloudCover↵    windSpeed↵    windGust↵    updatedAt↵  }↵}↵";

  // Add the variables to json doc
  doc["variables"]["input"]["shallowTempF"] = DS18B20.getTempF(shallowAddress);
  doc["variables"]["input"]["shallowTempC"] = DS18B20.getTempC(shallowAddress);
  doc["variables"]["input"]["deepTempF"] = DS18B20.getTempF(deepAddress);
  doc["variables"]["input"]["deepTempC"] = DS18B20.getTempC(deepAddress);
  doc["variables"]["input"]["airTempF"] = temp;
  doc["variables"]["input"]["airTempC"] = (temp - 32) * 5/9;
  doc["variables"]["input"]["cloudCover"] = cloudCover;
  doc["variables"]["input"]["windSpeed"] = windSpeed;
  doc["variables"]["input"]["windGust"] = windGust;
  doc["variables"]["input"]["runVersion"] = runVersion;

  // Serialize the json doc into a json string
  serializeJson(doc, json);

  // Api call
  https.begin(awsClient, api);
  https.addHeader("x-api-key", apiKey);
  https.addHeader("x-api-secret", apiSecret);
  https.addHeader("Content-Type", "application/json");
  https.POST(json);
  if (DEBUG) {
    Serial.println(json);
    https.writeToStream(&Serial);
  }
  https.end();
}

void readCurrentWeatherData(float *temp, float *cloudCover, float *windSpeed, float *windGust) {
  DynamicJsonDocument doc(1024);
  HTTPClient http;

  // Get weather from the lake
  http.begin("http://api.openweathermap.org/data/2.5/weather?lat=43.4897&lon=-71.1315&appid=MY_KEY&units=imperial");
  int httpCode = http.GET();

  if (httpCode > 0) {
    deserializeJson(doc, http.getString());
    *temp = doc["main"]["temp"];
    *cloudCover = doc["clouds"]["all"];
    *windSpeed = doc["wind"]["speed"];
    *windGust = doc["wind"]["gust"];
    if (DEBUG) Serial.println(http.getString());
  }

  http.end();
}

void readAndSendSystemInfo() {
  DynamicJsonDocument doc(1024);
  String json;
  HTTPClient https;

  // Add the variables to json doc
  doc["variables"]["input"]["runVersion"] = runVersion;
  doc["variables"]["input"]["batteryVoltage"] = batteryVoltage;
  doc["variables"]["input"]["batterymAh"] = batterymAh;
  doc["variables"]["input"]["counter"] = rtcMem.counter;
  doc["variables"]["input"]["totalCycleTime"] = rtcMem.totalCycleTime;
  doc["variables"]["input"]["voltage"] = ESP.getVcc();

  // Set base query in json doc (fetched from mock post in UI)
  // If first run we create the record, if not first run we update the record
  if (firstRun) {
    doc["query"] = "mutation CreateSensorInfo($input: CreateSensorInfoInput!, $condition: ModelSensorInfoConditionInput) {↵  createSensorInfo(input: $input, condition: $condition) {↵    createdAt↵    runVersion↵    batteryVoltage↵    batterymAh↵    counter↵    totalCycleTime↵    voltage↵    updatedAt↵  }↵}↵";
  } else {
    doc["query"] = "mutation UpdateSensorInfo($input: UpdateSensorInfoInput!, $condition: ModelSensorInfoConditionInput) {↵  updateSensorInfo(input: $input, condition: $condition) {↵    createdAt↵    runVersion↵    batteryVoltage↵    batterymAh↵    counter↵    totalCycleTime↵    voltage↵    updatedAt↵  }↵}↵";
  }

  // Serialize the json doc into a json string
  serializeJson(doc, json);

  // Api call
  https.begin(awsClient, api);
  https.addHeader("x-api-key", apiKey);
  https.addHeader("x-api-secret", apiSecret);
  https.addHeader("Content-Type", "application/json");
  https.POST(json);
  if (DEBUG) {
    Serial.println(json);
    https.writeToStream(&Serial);
  }
  https.end();
}

// Execution loop not needed with deep sleep (will reset each time)
void loop() { }` }
    </SyntaxHighlighter>
);

export default HardwareCode;
