import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import Pages, { pageKeys } from '../../Pages.js';
import { Link, withRouter } from 'react-router-dom';

const useStyles = createUseStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem',
        textDecoration: 'none',
        fontWeight: theme.typography.semiBold,
        fontSize: theme.sizes.small,
        color: theme.colors.lightText,
        '&:hover': {
            color: theme.colors.leftNavigationSelectedText
        }
    },
    active: {
        backgroundColor: theme.colors.leftNavigationSelected,
        color: theme.colors.leftNavigationSelectedText
    },
    iconContainer: {
        paddingRight: '0.5rem'
    }
}));

const LeftNavigationLink = ({ location, pageKey }) => {
    const styles = useStyles(useTheme());
    const page = Pages[pageKey] || {};
    // Count as active if the root of the current url matches the root of the route
    const isActive = location?.pathname === page.route || (page.route !== '/' && location?.pathname?.indexOf(page.route) === 0);

    return (
        <Link className={ [styles.container, isActive && styles.active].join(' ') } to={ page.route }>
            <span className={ styles.iconContainer }>{ page.icon }</span>
            { page.title }
        </Link>
    );
};

LeftNavigationLink.propTypes = {
    location: PropTypes.object,
    pageKey: PropTypes.oneOf(Object.keys(pageKeys))
};

export default withRouter(LeftNavigationLink);
