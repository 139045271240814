import React from 'react';
import Splash from './splash/Splash';
import LakeTemps from './lakeTemps/LakeTemps';
import LeftNavigation from './navigation/LeftNavigation';
import SailboatSensor from './sailboatSensor/SailboatSensor';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';
import Pages, { pageKeys } from '../Pages.js';
import AppConstants from '../AppConstants';
import BeehiveMonitor from './beehiveMonitor/BeehiveMonitor';
import HouseHumidity from './houseHumidity/HouseHumidity';
import Page from '../components/Page';

const useStyles = createUseStyles(theme => ({
    container: {
        position: 'relative',
        top: AppConstants.headerHeight,
        background: theme.colors.background,
        display: 'flex',
        minHeight: `calc(100% - ${ AppConstants.headerHeight })`
    },
    appBody: {
        flex: 1
    }
}));

const AppBody = () => {
    const styles = useStyles(useTheme());

    return (
        <div className={ styles.container }>
            <Switch>
                <Route
                    exact
                    path={ Pages[pageKeys.splash]?.route }
                    render={ props => (
                        <Page>
                            <Splash { ...props } />
                        </Page>
                    ) }
                />
                <Route exact path={ Pages[pageKeys.projects]?.route }>
                    <Redirect to={ Pages[pageKeys.projects]?.redirect } />
                </Route>
                <Route exact={ false } path={ Pages[pageKeys.projects]?.route }>
                    <LeftNavigation />
                    <div className={ styles.appBody }>
                        <Switch>
                            <Route
                                path={ Pages[pageKeys.lakeTemps]?.route }
                                render={ props => (
                                    <Page title={ Pages[pageKeys.lakeTemps]?.title }>
                                        <LakeTemps { ...props } />
                                    </Page>
                                ) }
                            />
                            <Route
                                path={ Pages[pageKeys.sailboatSensor]?.route }
                                render={ props => (
                                    <Page title={ Pages[pageKeys.sailboatSensor]?.title }>
                                        <SailboatSensor { ...props } />
                                    </Page>
                                ) }
                            />
                            <Route
                                path={ Pages[pageKeys.beehiveMonitor]?.route }
                                render={ props => (
                                    <Page title={ Pages[pageKeys.beehiveMonitor]?.title }>
                                        <BeehiveMonitor { ...props } />
                                    </Page>
                                ) }
                            />
                            <Route
                                path={ Pages[pageKeys.houseHumidity]?.route }
                                render={ props => (
                                    <Page title={ Pages[pageKeys.houseHumidity]?.title }>
                                        <HouseHumidity { ...props } />
                                    </Page>
                                ) }
                            />
                        </Switch>
                    </div>
                </Route>
            </Switch>
        </div>
    );
};

export default AppBody;
