export const colors = {
    seafoam: '#359d93',
    gold: '#eebd6b',
    seaBlue: '#bfe6ef',
    oceanBlue: '#447695',
    deepBlue: '#04082e',
    // Used in index.css for link coloring
    blue: '#1976d2',
    lightBlue: '#66C3FF',

    splashBlueDark: '#1e6496', // The color of the top of the original splash image
    splashBlueLight: '#609bb9', // The color of the top of the used splash image
    splashGold: '#f3d5a0',

    brightYellow: '#E6F000',
    yellow: '#eecf48',
    orange: '#f3ad42',
    red: '#c70c2f',
    green: '#5DE043',

    almostWhite: '#fdfdfd',
    haze: '#F5F5F5',
    smoke: '#A2A2A1',
    mediumGray: '#7e7e7e',

    iceSaberLight: '#ECF0F1',
    iceSaber: '#D8DFE4',
    iceSaberDark: '#BBC7CC',
    iceSaberDarkest: '#8A969E',

    darkMatterLight: '#4E6067',
    darkMatter: '#2F3C42',
    darkMatterDark: '#1D2528',
    darkMatterDarkest: '#000000'
};

const Theme = {
    // Later I may create a color switcher (dark theme) that allows these to be swapped out
    colors: {
        alpha: colors.splashBlueLight,
        beta: colors.gold,
        charlie: colors.seaBlue,
        delta: colors.oceanBlue,
        echo: colors.deepBlue,

        caution: colors.yellow,
        warning: colors.orange,
        error: colors.red,
        success: colors.green,

        lightestText: colors.almostWhite,
        lightText: colors.haze,
        mediumLightText: colors.iceSaberDarkest,
        mediumText: colors.mediumGray,
        mediumDarkText: colors.darkMatterLight,
        darkText: colors.darkMatterDarkest,

        headerBackground: colors.almostWhite,
        headerShadow: colors.iceSaberDark,

        background: colors.iceSaberLight,
        overlayBackground: colors.smoke,
        header: colors.blue,

        leftNavigationBackground: colors.darkMatterLight,
        leftNavigationSelected: colors.darkMatter,
        leftNavigationSelectedText: colors.lightBlue,

        specialCase: {
            ...colors
        }
    },
    sizes: {
        xxxxl: '3rem',
        xxxl: '2rem',
        xxl: '1.4rem',
        xl: '1.3rem',
        lg: '1.2rem',
        md: '1.1rem',
        normal: '1rem',
        petit: '0.9rem',
        small: '0.8rem',
        microscopic: '0.7rem'
    },
    typography: {
        light: 300,
        normal: 400,
        semiBold: 500,
        bold: 600,
        extraBold: 700,
        boldest: 900
    }
};

export default Theme;
