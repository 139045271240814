import React from 'react';

import PageHeader from '../../components/PageHeader.jsx';
import { Route, Switch } from 'react-router-dom';
import Pages, { pageKeys } from '../../Pages.js';
import BeehiveMonitorData from './BeehiveMonitorData';
import BeehiveMonitorAbout from './BeehiveMonitorAbout';
import BeehiveMonitorLearnings from './BeehiveMonitorLearnings';

const beehiveMonitorTabs = {
    DATA: Pages[pageKeys.beehiveMonitor]?.route,
    ABOUT: Pages[pageKeys.beehiveMonitorAbout]?.route,
    LEARNINGS: Pages[pageKeys.beehiveMonitorLearnings]?.route
};
const BeehiveMonitor = () => (
    <div>
        <PageHeader
            subtitle="A sensor that monitors humidity and temperatures within a beehive."
            /*
            tabs={ [{
                label: 'Data',
                value: beehiveMonitorTabs.DATA
            }, {
                label: 'About',
                value: beehiveMonitorTabs.ABOUT
            }, {
                label: 'Learnings',
                value: beehiveMonitorTabs.LEARNINGS
            }] }
            */
            title="Beehive Monitor"
        />
        <Switch>
            <Route path={ beehiveMonitorTabs.ABOUT }>
                <BeehiveMonitorAbout />
            </Route>
            <Route path={ beehiveMonitorTabs.LEARNINGS }>
                <BeehiveMonitorLearnings />
            </Route>
            <Route path={ beehiveMonitorTabs.DATA }>
                <BeehiveMonitorData />
            </Route>
        </Switch>
    </div>
);

export default BeehiveMonitor;
